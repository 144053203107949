import { Dispatch, SetStateAction } from "react";
import { Text } from "@chakra-ui/react";
import { RadioCard, RadioCardGroup } from "components/FormElements/RadioCardGroup";

export type ScheduleType = "list" | "stages";

const SelectableScheduleTypeOptions = [
  { label: "By Stages", value: "stages" },
  { label: "List", value: "list" },
];

interface SelectableScheduleTypeProps {
  setScheduleType: Dispatch<SetStateAction<ScheduleType>>;
  scheduleType: ScheduleType;
}

export default function SelectableScheduleType({ scheduleType, setScheduleType }: SelectableScheduleTypeProps) {
  return (
    <RadioCardGroup
      name="scheduleType"
      value={scheduleType}
      defaultValue={scheduleType}
      spacing="2"
      onChange={(value: any) => setScheduleType(value)}
      direction="row"
    >
      {SelectableScheduleTypeOptions.map(({ value, label }) => (
        <RadioCard includeCheckbox key={value} value={value}>
          <Text size="sm">{label}</Text>
        </RadioCard>
      ))}
    </RadioCardGroup>
  );
}
