import { useForm } from "react-hook-form";
import ActionRichTextAreaField from "components/FormElements/Fields/ActionRichTextAreaField";
import { forwardRef, useCallback, useEffect, useImperativeHandle } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { dirtyValues } from "helpers/form";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS, TASK_TYPES } from "definitions/constants";
import { Task } from "models";
import TextInputField from "components/FormElements/Fields/InputFields/TextInputField";
import TargetableTypeField from "components/FormElements/Fields/TargetableTypeField";
import { findTaskNotifier, isTask } from "features/Task/helpers";
import { Schema, schema } from "./schema";
import { DESCRIPTION_FIELD, MESSAGE_SUGGESTION_FIELD, TITLE_FIELD } from "./fields";
import useSubmitUpdateTaskForm from "./useSubmitUpdateTaskForm";
import { HorizontalGreyBlackField } from "components/DataDisplay/GreyBlackField";
import { assignedActionTargetText, taskTargetText } from "helpers/workflowActionableConverters";
import { Box } from "@chakra-ui/react";

const values = (task: Task): Schema => {
  if (!isTask(task)) {
    throw new Error("Action is not of type task");
  }

  return {
    taskType: task?.taskType,
    title: task?.title,
    description: task?.description,
    messageTarget: task?.messageTarget
      ? {
        targetType: task?.messageTarget?.targetType,
        targetId: task?.messageTarget?.target?.id,
      }
      : null,
    messageBody: task?.messageBody?.bodyTrix ?? task?.messageBody?.body,
  };
};

interface UpdateTaskFormProps {
  task: Task;
  onSuccess: () => void;
  setIsSubmitLoading: (isLoading: boolean) => void;
  setIsFormDirty: (isLoading: boolean) => void;
}

const UpdateTaskForm = forwardRef(
  ({ task, onSuccess, setIsSubmitLoading, setIsFormDirty }: UpdateTaskFormProps, ref) => {
    const queryClient = useQueryClient();
    const {
      control,
      formState: { errors, dirtyFields, isDirty },
      handleSubmit,
      register,
      reset,
    } = useForm<Schema>({
      mode: "onBlur",
      resolver: yupResolver<Schema>(schema, { stripUnknown: true }),
      defaultValues: values(task),
    });
    const { onSubmit: onSubmitForm, isLoading: onSubmitLoading } = useSubmitUpdateTaskForm({
      taskId: task.id,
      onSuccess,
    });

    useEffect(() => {
      setIsFormDirty(isDirty);
    }, [isDirty, setIsFormDirty]);

    useEffect(() => {
      setIsSubmitLoading(onSubmitLoading);
    }, [onSubmitLoading, setIsSubmitLoading]);

    const handleOnSubmit = useCallback(
      (onSuccessCallback: () => void) => {
        handleSubmit((data) => {
          // handleSubmit built in - if the form is invalid, this function will not be called.
          // Because of this, if we pull "updateData" and its an empty object (no dirty fields),
          // we do not need to call the onSubmit function.
          const updateData = dirtyValues(data, dirtyFields);
          if (updateData.messageTarget?.targetId && !updateData.messageTarget?.messageTarget) {
            updateData.messageTarget.targetType = data?.messageTarget?.targetType;
          }
          if (Object.keys(updateData).length === 0) {
            onSuccess?.();
            onSuccessCallback?.();
            return;
          }
          onSubmitForm(updateData, {
            onSuccess: () => {
              queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.newHireJourneys, task?.newHireJourneyId] });
              reset({}, { keepValues: true });
              onSuccessCallback?.();
              onSuccess?.();
            },
            onError: (error) => {
              console.log("error", error);
            },
          });
        })();
      },
      [handleSubmit, dirtyFields, onSubmitForm, onSuccess, task?.newHireJourneyId, queryClient, reset],
    );

    // Expose form methods to parent via ref
    useImperativeHandle(ref, () => ({
      handleSubmit: (onSuccessCallback: () => void) => handleOnSubmit(onSuccessCallback),
    }));

    return (
      <>
        <Box my="4">
          <HorizontalGreyBlackField size="lg" label="To">
            {taskTargetText(task)}
          </HorizontalGreyBlackField>
        </Box>
        <TextInputField {...TITLE_FIELD} errors={errors} register={register} />
        {[TASK_TYPES.CUSTOM, TASK_TYPES.SEND_MESSAGE_PROMPT].includes(task.taskType) && (
          <ActionRichTextAreaField
            actionType="task"
            targetType={task.taskNotifier?.workflowAction?.actionable?.targetType}
            {...DESCRIPTION_FIELD}
            errors={errors}
            control={control}
          />
        )}
        {TASK_TYPES.SEND_MESSAGE_PROMPT === task.taskType && (
          <>
            <TargetableTypeField<Schema>
              errors={errors}
              control={control}
              label="Message Recipient"
              fieldName="messageTarget"
              targetTitle="Message Target"
            />
            <ActionRichTextAreaField
              actionType="task"
              targetType={task.taskNotifier?.workflowAction?.actionable?.targetType}
              {...MESSAGE_SUGGESTION_FIELD}
              errors={errors}
              control={control}
            />
          </>
        )}
      </>
    );
  },
);

export default UpdateTaskForm;
