import { useEffect } from "react";
import {
  Alert, AlertIcon, Card, CardBody, Heading,
} from "@chakra-ui/react";
import AppPage from "layouts/AppPage";
import { useNavbar } from "hooks/useNavbar";
import PendingNewHireJourneyCardTable from "features/NewHireJourney/PendingNewHireJourneyCardTable";

export default function PendingNewHireJourneysPage() {
  const { setBreadcrumbs, setPageTitle } = useNavbar();

  useEffect(() => {
    setBreadcrumbs([{ name: "Pending New Hires", isCurrentPage: true }]);
    setPageTitle("Pending New Hires");
  }, [setBreadcrumbs, setPageTitle]);

  return (
    <AppPage>
      <Card mt="8" p="4">
        <Alert status="warning">
          <AlertIcon />
          Please kick off the onboarding for these New Hires!
        </Alert>
        <CardBody>
          <Heading size="xs" textTransform="capitalize">
            Pending New Hires
          </Heading>
          <PendingNewHireJourneyCardTable />
        </CardBody>
      </Card>
    </AppPage>
  );
}
