import { Row, createColumnHelper } from "@tanstack/react-table";
import { Action } from "models/automation";
import CardTable, { ControlledCardTableProps } from "components/Table/CardTable/ControlledCardTable";
import { workflowTriggerSort } from "helpers/workflowActionableConverters";
import { SystemStyleObject, useColorModeValue } from "@chakra-ui/react";
import ActionListCard from "./ActionListCard";

const columnHelper = createColumnHelper<Action>();

const columnDefs = [
  columnHelper.accessor((row) => row, {
    header: "Type",
    accessorKey: "actionable.actionType",
  }),
  columnHelper.accessor((row) => row, {
    id: "target",
    header: "Recipient/Assignee",
  }),
  columnHelper.accessor((row) => row, {
    id: "actionableTrigger",
    header: "Trigger",
    sortingFn: (rowA: Row<Action>, rowB: Row<Action>) => workflowTriggerSort(rowA, rowB, (row) => (row.original.trigger ? row.original.trigger : row.original.actionable.trigger)),
  }),
];

interface ActionCardTableProps {
  data: Action[];
  initialPagination?: { pageIndex: number; pageSize: number };
  isLoading?: boolean;
  getCardProps?: () => object;
  setTable?: () => object;
  emptyStateComponent?: React.ReactNode;
  sx: SystemStyleObject;
}

export default function ActionCardTable({
  data,
  isLoading,
  setTable,
  getCardProps,
  emptyStateComponent,
  sx,
  ...rest
}: ActionCardTableProps) {
  const tableProps: Pick<
  ControlledCardTableProps<Action>,
  "columns" | "initialPagination" | "defaultSort" | "emptyStateComponent" | "data"
  > = {
    columns: columnDefs,
    initialPagination: { pageSize: 20, pageIndex: 0 },
    defaultSort: [{ id: "actionableTrigger", desc: true }],
    emptyStateComponent,
    data,
  };
  const bg = useColorModeValue("gray.100", "gray.600");

  return (
    <CardTable
      {...tableProps}
      isLoading={isLoading}
      cardComponent={ActionListCard}
      getCardProps={getCardProps}
      getTdProps={() => ({ py: "6px" })}
      getContainerProps={() => ({ bg })}
      setTable={setTable}
      sx={{
        "& tbody tr:first-of-type td:first-of-type": {
          paddingTop: "20px",
        },
        mt: "2px",
        ...sx,
      }}
      {...rest}
    />
  );
}

ActionCardTable.defaultProps = {
  isLoading: false,
  initialPagination: undefined,
  getCardProps: () => ({}),
  setTable: () => ({}),
  emptyStateComponent: null,
};
