import { Flex, FlexProps, Text } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

interface GreyBlackFieldProps extends FlexProps {
  label: string;
  labelProps?: object;
  valueProps?: object;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
}

function getSizeProps(size: GreyBlackFieldProps["size"]) {
  let labelFontSize;
  let valueFontSize;
  switch (size) {
    case "xs":
      labelFontSize = "2xs";
      valueFontSize = "xs";
      break;
    case "sm":
      labelFontSize = "xs";
      valueFontSize = "sm";
      break;
    case "lg":
      labelFontSize = "md";
      valueFontSize = "lg";
      break;
    case "xl":
      labelFontSize = "lg";
      valueFontSize = "xl";
      break;
    default:
      labelFontSize = "sm";
      valueFontSize = "md";
      break;
  }
  return {
    labelProps: {
      fontSize: labelFontSize,
    },
    valueProps: {
      fontSize: valueFontSize,
    },
  };
}

export default function GreyBlackField({
  label,
  size = "md",
  labelProps = {},
  valueProps = {},
  children,
  ...rest
}: GreyBlackFieldProps) {
  const { labelProps: sizeLabelProps, valueProps: sizeValueProps } = getSizeProps(size);
  return (
    <Flex align="flex-start" direction="column" {...rest}>
      <Text {...sizeLabelProps} color="notBlack.50" {...labelProps}>
        {label}
      </Text>
      {typeof children === "string" ? (
        <Text {...sizeValueProps} fontWeight="medium" lineHeight="1" {...valueProps}>
          {children}
        </Text>
      ) : (
        children
      )}
    </Flex>
  );
}

export function HorizontalGreyBlackField({
  label,
  size = "md",
  labelProps = {},
  valueProps = {},
  children,
  ...rest
}: GreyBlackFieldProps) {
  const { labelProps: sizeLabelProps, valueProps: sizeValueProps } = getSizeProps(size);
  return (
    <Flex align="center" gap="2" {...rest}>
      <Text {...sizeLabelProps} color="notBlack.50" {...labelProps}>
        {label}
      </Text>
      {typeof children === "string" ? (
        <Text {...sizeValueProps} fontWeight="medium" lineHeight="1" {...valueProps}>
          {children}
        </Text>
      ) : (
        children
      )}
    </Flex>
  );
}
