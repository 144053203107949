import { Heading, VStack } from "@chakra-ui/react";
import { ExtendedNewHireJourney } from "models";
import AssignedPathsTable from "features/AssignedPath/PathsTable";

interface PathsTabsProps {
  newHireJourney: ExtendedNewHireJourney;
}

export default function PathsTabs({ newHireJourney }: PathsTabsProps) {
  return (
    <VStack align="flex-start">
      <Heading as="h6" size="xxs">
        Paths
      </Heading>
      <AssignedPathsTable data={newHireJourney?.assignedPaths} isLoading={!newHireJourney} />
    </VStack>
  );
}
