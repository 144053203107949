import { Flex, Icon, IconProps, SystemStyleObject, Text } from "@chakra-ui/react";
import CaminoEmail from "components/Icon/CaminoEmail";
import CaminoMessage from "components/Icon/CaminoMessage";
import CaminoTask from "components/Icon/CaminoTask";

function sizeProps(size: string) {
  switch (size) {
    case "xs":
      return {
        fontSize: "sm",
        boxSize: "2",
      };
    case "sm":
      return {
        fontSize: "md",
        boxSize: "4",
      };
    case "md":
      return {
        fontSize: "xl",
        boxSize: "5",
      };
    case "lg":
      return {
        fontSize: "2xl",
        boxSize: "5",
      };
    case "xl":
      return {
        fontSize: "3xl",
        boxSize: "6",
      };
    default:
      return {
        fontSize: "xl",
        boxSize: "4",
      };
  }
}

interface ActionTypeDisplayProps {
  actionName: string;
  icon: React.ComponentType<IconProps>;
  size?: string;
  textProps?: object;
  iconProps?: object;
  containerProps?: object;
}

function ActionTypeDisplay({
  actionName,
  icon,
  size = "md",
  textProps = {},
  iconProps = {},
  containerProps = {},
}: ActionTypeDisplayProps) {
  const { fontSize, boxSize } = sizeProps(size);
  return (
    <Flex align="center" gap="2" {...containerProps}>
      <Icon as={icon} boxSize={boxSize} color="fg.subtle" {...iconProps} />
      <Text fontSize={fontSize} color="fg.muted" fontWeight="bold" {...textProps}>
        {actionName}
      </Text>
    </Flex>
  );
}

export function EmailActionType({ ...rest }: Pick<ActionTypeDisplayProps, "size" | "textProps" | "iconProps" | "containerProps">) {
  return <ActionTypeDisplay actionName="Email" icon={CaminoEmail} {...rest} />;
}

export function ChatActionType({ ...rest }: Pick<ActionTypeDisplayProps, "size" | "textProps" | "iconProps" | "containerProps">) {
  return <ActionTypeDisplay actionName="Chat Message" icon={CaminoMessage} {...rest} />;
}

export function TaskActionType({ ...rest }: Pick<ActionTypeDisplayProps, "size" | "textProps" | "iconProps" | "containerProps">) {
  return <ActionTypeDisplay actionName="Task" icon={CaminoTask} {...rest} />;
}

interface DynamicActionTypeProps extends Pick<ActionTypeDisplayProps, "size" | "textProps" | "iconProps" | "containerProps"> {
  actionType: string;
}

export function DynamicActionType({ actionType, ...rest }: DynamicActionTypeProps) {
  switch (actionType) {
    case "email":
      return <EmailActionType {...rest} />;
    case "chat":
      return <ChatActionType {...rest} />;
    case "task":
    case "task_notifier":
    case "task_reminder":
      return <TaskActionType {...rest} />;
    default:
      return null;
  }
}
