import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Text,
  Flex,
} from "@chakra-ui/react";
import { fromActionId } from "helpers/newHireJourney";
import { CombinedActionId } from "models/automation/combinedAction";
import { useCombinedActionDetailQuery } from "features/CombinedAction/hooks";
import EmailDrawerForm from "./EmailDrawerForm";
import TaskDrawerForm from "./TaskDrawerForm";
import ChatMessageDrawerForm from "./ChatMessageDrawerForm";
import CombinedActionStatusTag from "components/Tag/CombinedActionStatusTag";

function ErrorText() {
  return <Text>Error</Text>;
}

type ActionType = "task" | "email" | "chat";

function getActionForm(actionType: ActionType) {
  switch (actionType) {
    case "email":
      return { title: "Email", form: EmailDrawerForm };
    case "task":
      return { title: "Task", form: TaskDrawerForm };
    case "chat":
      return { title: "Chat Message", form: ChatMessageDrawerForm };
    default:
      return { title: "Error", form: ErrorText };
  }
}

interface AssignedActionSideDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  resourceId: CombinedActionId;
}

export default function AssignedActionSideDrawer({ isOpen, onClose, resourceId }: AssignedActionSideDrawerProps) {
  const { data: combinedAction } = useCombinedActionDetailQuery(resourceId, { enabled: !!resourceId });
  const { actionType } = fromActionId(resourceId);
  const { title, form: ActionForm } = getActionForm(actionType as ActionType);

  if (!resourceId) return null;

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader paddingBottom="4" fontSize="xl" fontWeight="semibold" borderBottomWidth="2px">
          <Flex align="Center" gap="4">
            {title}
            <Flex width="fit-content" my="2">
              <CombinedActionStatusTag action={combinedAction} />
            </Flex>
          </Flex>
        </DrawerHeader>

        <DrawerBody>
          {!combinedAction ? (
            <Text>Loading...</Text>
          ) : (
            <ActionForm onClose={onClose} combinedAction={combinedAction} />
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
