import { SingleValue, AsyncSelect } from 'chakra-react-select';
import clsx from 'clsx';
import { API_ROUTES } from 'definitions/constants/routeConstants';
import { debounce } from 'lodash';
import { useState } from 'react';
import { generatePath } from 'react-router-dom';
import apiClient from 'services/ApiClient';

type LocationSuggestion = {
  placeId: string;
  label: string;
};

export type LocationDetails = {
  id: string;
  displayName: string;
  label?: string;
  timezone?: string;
  coordinates: {
    latitude: string;
    longitude: string;
  };
  addressComponents: {
    street1?: string;
    street2?: string;
    city?: string;
    state?: string;
    postalCode?: string;
    country?: string;
  };
};

function LocationSelect(
  {
    onChange,
    className = undefined,
    containerStyles = {},
    variant = "outline",
    initialValue = undefined,
  }: {
    onChange: (value: LocationDetails) => void,
    className?: string;
    containerStyles?: object;
    variant?: "outline" | "floating"
    initialValue?: LocationSuggestion | undefined
  }) {
  const [selectedValue, setSelectedValue] = useState(initialValue || null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const loadOptions = debounce(
    (inputValue: string, callback: (options: LocationSuggestion[]) => void) => {
      if (!inputValue) return callback([]);

      apiClient.get<LocationSuggestion[]>(`${API_ROUTES.autocomplete.locations.index}?input=${inputValue}`)
        .then(callback);
    },
    400,
  );

  const handleChange = async (selectedOption: SingleValue<LocationSuggestion>) => {
    setSelectedValue(selectedOption);
    setMenuIsOpen(false);
    if (!selectedOption) return;
    try {
      const location = await apiClient.get<LocationDetails>(generatePath(API_ROUTES.autocomplete.locations.show, { id: selectedOption.placeId }));
      onChange({ ...location, label: selectedOption.label });
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };

  return (
    <AsyncSelect<LocationSuggestion>
      cacheOptions
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => option.placeId}
      value={selectedValue}
      loadOptions={loadOptions}
      onChange={handleChange}
      onInputChange={(inputValue) => setMenuIsOpen(!!inputValue && isFocused)}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      menuIsOpen={menuIsOpen}
      placeholder={variant === "floating" ? " " : "Search for a location"}
      // Styles
      variant={variant}
      components={{ DropdownIndicator: null }}
      useBasicStyles
      classNamePrefix="chakra-react-select"
      className={clsx(
        "chakra-react-select-container-single-select",
        {
          "chakra-react-select--has-value": !!selectedValue,
        },
        "location",
        className,
      )}
      colorScheme="blue"
      chakraStyles={{
        container: (provided) => ({
          ...provided,
          cursor: "pointer",
          ...containerStyles,
        }),
        option: (provided) => ({
          ...provided,
          color: "fg.muted",
        }),
        valueContainer: (provided) => ({
          ...provided,
          className: "single-select",
        }),
        menu: (provided) => ({
          ...provided,
          marginTop: 0,
        }),
        clearIndicator: (provided) => ({
          ...provided,
          width: "fit-content",
          height: "fit-content",
          marginRight: "1rem",
          _hover: {
            color: "notBlack.700",
          },
        }),
        crossIcon: (provided) => ({
          ...provided,
          width: "12px",
          height: "12px",
        }),
      }}
      styles={{
        menuPortal: (provided) => ({ ...provided, zIndex: 1400 }),
        valueContainer: (provided) => ({
          ...provided,
          className: "single-select",
        }),
      }}
      // Not sure if we need all of these
      menuPortalTarget={document.body}
    />
  );
};

export default LocationSelect;
