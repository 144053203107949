/* eslint-disable no-nested-ternary */
import { Tag, TagProps } from "@chakra-ui/react";
import { combinedActionStatusInfo } from "helpers/assignedAction";
import { CombinedAction } from "models/automation/combinedAction";

interface SendDateWithImmediateTagProps extends TagProps {
  action: CombinedAction;
}

export default function CombinedActionStatusTag({ action, ...rest }: SendDateWithImmediateTagProps) {
  if (!action) {
    return null;
  }
  const { colorScheme, text } = combinedActionStatusInfo(action);
  if (!colorScheme) {
    return null;
  }
  return (
    <Tag
      variant="outline"
      color={`${colorScheme}.DEFAULT`}
      border={`1px solid var(--chakra-colors-${colorScheme}-DEFAULT)`}
      bg={`${colorScheme}.background`}
      borderRadius="md"
      boxShadow="none"
      {...rest}
    >
      {text}
    </Tag>
  );
}
