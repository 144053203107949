import {
  Card,
  CardBody,
  CardHeader,
  Icon,
  HStack,
  Heading,
  Text,
  Skeleton,
  Tag,
  Alert,
  AlertIcon,
  Stack,
  Button,
  StackProps,
  TagLeftIcon,
  TagLabel,
  Flex,
  Spacer,
  VStack,
} from "@chakra-ui/react";
import ButtonWithAlert from "components/Button/ButtonWithConfirm";
import { Action, EmailRecipient, PathActionable } from "models/automation";
import { IconType } from "react-icons";
import { PropsWithChildren } from "react";
import { scheduledActionStatusConverter, scheduledActionToStatusColor } from "helpers/scheduledWorkflowConverters";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { generatePath } from "react-router-dom";
import { User } from "models/user";
import { ASSIGNED_ACTION_STATUS_TYPES, QUERY_KEYS } from "definitions/constants";
import apiClient from "services/ApiClient";
import { OnboardingJourneyNetworkRelation } from "models/record_target";
import { useQuery } from "@tanstack/react-query";
import { EmailIcon } from "@chakra-ui/icons";
import { titleize } from "helpers/string";
import IssueExpander from "features/AssignedAction/IssueExpander";
import {
  AssignedActionOriginalAction,
  AssignedActionScheduledAt,
  AssignedActionTargetText,
  AssignedActionTriggerText,
} from "features/AssignedAction/DisplayInfo";
import { ActionTargetText, ActionTriggerAndTimingText } from "features/Action/DisplayInfo";
import UpdateActionButtonWithModal from "components/ModalForm/PathActionForm/UpdateActionButtonWithModal";
import AssignedActionMenuButton from "features/AssignedAction/AssignedActionMenuButton";
import { automationTriggerText } from "helpers/workflowActionableConverters";
import { isAssignedAction } from "helpers/action";
import { DynamicActionType } from "components/ActionType";
import ActionableContent from "./ActionableContent";

interface ActionCardProps {
  action: Action
  isLoading: boolean;
  onTestSend: () => void;
  onDelete: () => void;
}

interface ActionAttributeCardProps extends PropsWithChildren, StackProps {
  icon?: IconType;
  text?: string | JSX.Element;
}

export function ActionAttributeCard({ icon, text, children, ...rest }: ActionAttributeCardProps) {
  return (
    <HStack
      fontSize="lg"
      border="1px"
      borderColor="gray.500"
      paddingX="15px"
      paddingY="5px"
      borderRadius="5px"
      {...rest}
    >
      {icon && <Icon as={icon} color="gray.500" />}
      {text && <Text>{text}</Text>}
      {children}
    </HStack>
  );
}

ActionAttributeCard.defaultProps = {
  icon: null,
  text: null,
};

function RecipientTag({ recipient }: { recipient: EmailRecipient }) {
  const appendEmailType = (value: string, emailType: string) => {
    const abbreviation = emailType?.slice(0, 1);
    if (!abbreviation) return value;
    return `${value} (${abbreviation})`;
  };
  let { id, targetType } = { id: "", targetType: "" };
  if (recipient.customEmail) {
    // No-op
  } else {
    id = recipient.recipient.targetId;
    targetType = recipient.recipient.targetType;
  }
  const { data: userData } = useQuery<User>({
    queryKey: [QUERY_KEYS.users, id],
    queryFn: () => apiClient.get<User>(generatePath(API_ROUTES.users.show, { id })),
    enabled: targetType === "User",
  });
  const { data: relationData } = useQuery<OnboardingJourneyNetworkRelation>({
    queryKey: ["OnboardingJourneyNetworkRelation", id],
    queryFn: () => apiClient.get<OnboardingJourneyNetworkRelation>(
      generatePath(API_ROUTES.onboardingJourneyNetworkRelations.show, { id }),
    ),
    enabled: targetType === "OnboardingJourneyNetworkRelation",
  });
  let values = { value: "Loading", icon: EmailIcon };
  if (recipient.customEmail) {
    values = { value: recipient.customEmail, icon: EmailIcon };
  } else if (targetType === "User") {
    if (userData) values = { value: appendEmailType(userData.fullName, recipient.emailType), icon: EmailIcon };
  } else if (targetType === "OnboardingJourneyNetworkRelation") {
    if (relationData) values = { value: appendEmailType(titleize(relationData.name), recipient.emailType), icon: EmailIcon };
  }

  return (
    <Tag size="lg" variant="subtle">
      <TagLeftIcon boxSize="12px" as={values.icon} />
      <TagLabel>{values.value}</TagLabel>
    </Tag>
  );
}

function RecipientsContainer({ label, recipients }: { label: string; recipients: EmailRecipient[] }) {
  return (
    <>
      <Heading as="h3" size="xxs" fontWeight="bold">
        {label}
      </Heading>
      <Flex
        border="1px solid"
        borderColor="fg.subtle"
        gap={2}
        borderRadius="lg"
        p={2}
        minH="40px"
        minWidth="60%"
        maxWidth="100%"
        flexWrap="wrap"
        alignItems="center"
      >
        {recipients.map((recipient, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <RecipientTag key={index} recipient={recipient} />
        ))}
        <Spacer />
      </Flex>
    </>
  );
}
function AdditionalRecipients({ actionable }: { actionable: PathActionable }) {
  if (actionable.actionType !== "email") return null;
  return (
    <>
      {actionable.additionalRecipients && actionable.additionalRecipients.length > 0 && (
        <RecipientsContainer label="Additional Recipients" recipients={actionable.additionalRecipients} />
      )}
      {actionable.ccRecipients && actionable.ccRecipients.length > 0 && (
        <RecipientsContainer label="Cc" recipients={actionable.ccRecipients} />
      )}
      {actionable.bccRecipients && actionable.bccRecipients.length > 0 && (
        <RecipientsContainer label="Bcc" recipients={actionable.bccRecipients} />
      )}
    </>
  );
}

function ScheduledAdditionalRecipients({ action }: { action: AssignedAction }) {
  if (action.actionType !== "email") return null;
  return (
    <>
      {action.additionalRecipients && action.additionalRecipients.length > 0 && (
        <RecipientsContainer label="Additional Recipients" recipients={action.additionalRecipients} />
      )}
      {action.ccRecipients && action.ccRecipients.length > 0 && (
        <RecipientsContainer label="Cc" recipients={action.ccRecipients} />
      )}
      {action.bccRecipients && action.bccRecipients.length > 0 && (
        <RecipientsContainer label="Bcc" recipients={action.bccRecipients} />
      )}
    </>
  );
}

function ActionInformation({ action }: { action: Action }) {
  return (
    <VStack mb={4} spacing="4" align="start">
      <HStack spacing="4" ml="4">
        <DynamicActionType actionType={action.actionType} size="sm" textProps={{ fontWeight: "normal", color: "var(--chakra-colors-body-text)" }} />
        <ActionTargetText fontSize="lg" action={action} />
        <ActionTriggerAndTimingText fontSize="lg" action={action} />
        {action?.actionable.actionType === "task" && action.actionable.dueDateTrigger && (
          <Text fontSize="lg">
            {"📅 Due "}
            {automationTriggerText(action.actionable.dueDateTrigger)}
          </Text>
        )}
      </HStack>
      {action?.actionable && <AdditionalRecipients actionable={action?.actionable} />}
    </VStack>
  );
}

function ScheduledActionInformation({ action }: { action: AssignedAction }) {
  return (
    <Stack spacing="4">
      <Flex gap="4" ml="4">
        <DynamicActionType actionType={action.actionType} size="sm" textProps={{ fontWeight: "normal", color: "var(--chakra-colors-body-text)" }} />
        <AssignedActionTargetText fontSize="lg" assignedAction={action} />
        <AssignedActionScheduledAt fontSize="lg" assignedAction={action} />
        <AssignedActionTriggerText fontSize="lg" assignedAction={action} />
        {/* TODO: - add due date here, or perhaps just wait until Task model is displayed directly */}
      </Flex>
      <ScheduledAdditionalRecipients action={action} />
      <Flex gap="4" ml="4">
        <AssignedActionOriginalAction fontSize="md" assignedAction={action} />
      </Flex>
      <IssueExpander assignedAction={action} />
    </Stack>
  );
}

interface WorkflowActionButtonsProps {
  onDelete: () => void;
  action: Action;
}

function WorkflowActionButtons({ action, onDelete }: WorkflowActionButtonsProps) {
  return (
    <HStack my={8} justifyContent="center">
      <UpdateActionButtonWithModal action={action} />
      <ButtonWithAlert
        buttonText="Delete Action"
        header="Delete Action"
        body="Are you sure you want to delete this Action? It can't be undone."
        handleConfirm={onDelete}
        confirmButtonText="Delete"
      />
    </HStack>
  );
}

export function ActionCard({ action, onDelete, onTestSend, isLoading }: ActionCardProps) {
  const isScheduledAction = isAssignedAction(action);
  const actionable = isScheduledAction && action ? action.workflowAction.actionable : (action as Action)?.actionable;

  return (
    <Card>
      <Skeleton isLoaded={!isLoading}>
        <CardHeader mt="4" pb={0} display="flex" justifyContent="space-between" flexDirection="row">
          <Flex w="80%" gap="4">
            <Heading size="xs">{actionable?.name}</Heading>
          </Flex>
          <Button
            mr="12"
            isDisabled={!["email", "chat", "task"].includes((action as Action)?.actionable?.actionType)}
            onClick={onTestSend}
            colorScheme="gray"
            variant="outline"
          >
            Test Send
          </Button>
        </CardHeader>
        <CardBody>
          <ActionInformation action={action} />
          <ActionableContent content={actionable} />
        </CardBody>
        <WorkflowActionButtons action={action as Action} onDelete={onDelete} />
      </Skeleton>
    </Card>
  );
}

interface AssignedActionCardProps {
  action: AssignedAction;
  isLoading: boolean;
  onTestSend: () => void;
  onDelete: () => void;
}

export function AssignedActionCard({ action, onDelete, onTestSend, isLoading }: AssignedActionCardProps) {
  return (
    <Card>
      <Skeleton isLoaded={!isLoading}>
        <Alert status="info">
          <AlertIcon />
          <Text>
            {"Updating this action is only for "}
            <Text as="mark">{`${action?.onboardingJourney?.user?.fullName}`}</Text>
          </Text>
        </Alert>
        <CardHeader mt="4" pb={0} display="flex" justifyContent="space-between" flexDirection="row">
          <Flex w="80%" gap="4">
            <HStack align="center" spacing="4">
              <Heading size="xs">{action?.name}</Heading>
              <Tag variant="solid" size="md" colorScheme={scheduledActionToStatusColor(action)}>
                {scheduledActionStatusConverter(action?.status)}
              </Tag>
            </HStack>
          </Flex>
          {action?.status !== ASSIGNED_ACTION_STATUS_TYPES.PROCESSED ? (
            <Flex>
              <AssignedActionMenuButton assignedAction={action} editRedirect={false} />
            </Flex>
          ) : null}
        </CardHeader>
        <CardBody>
          <ScheduledActionInformation action={action} />
          <ActionableContent
            content={{
              ...action,
              messageBody: action?.task?.messageBody,
              messageTarget: action?.task?.messageTarget,
            }}
          />
        </CardBody>
      </Skeleton>
    </Card>
  );
}
