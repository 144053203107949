/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import {
  Heading, Text, Icon, Card, CardBody, Skeleton, Flex, Tooltip,
} from "@chakra-ui/react";
import { Link as RouterLink, generatePath } from "react-router-dom";
import { PEOPLE_TEAM_ROUTES } from "definitions/constants/routeConstants";
import {
  assignedActionTargetText,
  noNotifierActionableTypeConverter,
  noNotifierActionableTypeToIcon,
} from "helpers/workflowActionableConverters";
import { truncate } from "lodash";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { ASSIGNED_ACTION_STATUS_TYPES } from "definitions/constants";
import { HorizontalGreyBlackField } from "components/DataDisplay/GreyBlackField";
import AssignedActionCard, { DependencyInfo } from "features/AssignedAction/AssignedActionCardTable/AssignedActionCard";
import AssignedActionStatus from "features/AssignedAction/AssignedActionStatus";
import AssignedActionMenuButton from "features/AssignedAction/AssignedActionMenuButton";
import { AssignedActionScheduledAt } from "features/AssignedAction/DisplayInfo";
import IssueExpander from "features/AssignedAction/IssueExpander";

interface CombinedActionCardProps {
  rowData: AssignedAction;
  getCardProps: () => object;
}

export default function CombinedActionCard({
  rowData: assignedAction,
  getCardProps,
  ...rest
}: CombinedActionCardProps) {
  if (!assignedAction) {
    return <Skeleton height="100px" />;
  }
  if (["chat", "email"].includes(assignedAction.actionType)) {
    return <AssignedActionCard rowData={assignedAction} getCardProps={getCardProps} {...rest} />;
  }
  const icon = noNotifierActionableTypeToIcon({ actionType: assignedAction.actionType, taskType: assignedAction.taskType });
  const readableActionType = noNotifierActionableTypeConverter({
    actionType: assignedAction.actionType,
    taskType: assignedAction.taskType,
  });
  let readableActionName = assignedAction.name.length >= 40
    ? truncate(assignedAction.name, { length: 43, omission: "..." })
    : assignedAction.name;
  if (readableActionName.startsWith("Notification:")) {
    readableActionName = readableActionName.replace("Notification: ", "");
  }
  const readableTarget = assignedActionTargetText(assignedAction);

  return (
    <Skeleton isLoaded={!!assignedAction} align="center">
      <Flex direction="column" maxW="1000px">
        <Flex bg="#FEEBC8" borderTopRadius="var(--card-radius)" pl="8" py="1">
          <Heading size="xxs" color="gray.700">
            Assigned Action
          </Heading>
        </Flex>
        <Card borderTopRadius="0" p="0" direction="row" boxShadow="md" mb="2" overflow="hidden" {...rest}>
          <Tooltip label={readableActionType} aria-label={`Action Type ${readableActionType}`}>
            <Flex
              padding="4"
              objectFit="cover"
              direction="column"
              align="center"
              justify="center"
              bg="brand.400"
              width="100px"
            >
              <Icon as={icon} w={8} h={8} color="white" />
              <Text fontSize="md" fontWeight="medium" textAlign="center" color="white" whiteSpace="normal">
                {readableActionType}
              </Text>
            </Flex>
          </Tooltip>
          <CardBody minW="600px" maxW="1000px" display="flex" p="4">
            <Flex direction="column" align="flex-start" width="100%">
              <Flex justify="space-between" width="100%">
                <Flex gap="4" align="center">
                  <RouterLink to={generatePath(PEOPLE_TEAM_ROUTES.assignedActions.show, { id: assignedAction.id })}>
                    <Heading size="xs" textAlign="start">
                      {readableActionName}
                    </Heading>
                  </RouterLink>
                  <AssignedActionStatus assignedAction={assignedAction} />
                </Flex>
                {assignedAction.status !== ASSIGNED_ACTION_STATUS_TYPES.PROCESSED && (
                  <AssignedActionMenuButton assignedAction={assignedAction} />
                )}
              </Flex>
              <Flex mt="1" align="center" gap={8}>
                <Tooltip label="Recipient" aria-label="Recipient">
                  <HorizontalGreyBlackField
                    label="For"
                    ml="4"
                  >
                    {readableTarget}
                  </HorizontalGreyBlackField>
                </Tooltip>
                <AssignedActionScheduledAt assignedAction={assignedAction} />
              </Flex>
              {assignedAction.trigger.type === "dependency" && (
                <DependencyInfo mt="2" assignedAction={assignedAction} />
              )}
              <IssueExpander mt="2" assignedAction={assignedAction} />
            </Flex>
          </CardBody>
        </Card>
      </Flex>
    </Skeleton>
  );
}
