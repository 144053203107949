import AppPage from "layouts/AppPage";
import {
  Card, CardBody, Flex, Icon, Stack, Text,
} from "@chakra-ui/react";
import SearchBar from "components/SearchBar";
import { useCallback, useEffect, useState } from "react";
import TasksTable from "components/Table/TasksTable";
import { useNavbar } from "hooks/useNavbar";
import useCaminoStore from "hooks/useCaminoStore";
import { useSearchParams } from "react-router-dom";
import NewHireJourneySelect from "components/Form/Select/NewHireJourneySingleSelect";
import { NewHireJourney } from "models/newHire";
import { pullUniqueNewHireJourneysFromTasks } from "helpers/newHireJourney";
import { BaseTask } from "models/task";
import { Task } from "models";
import SelectableTasksByStatus, { TaskStatusType } from "components/Selectable/SelectableTasksByStatus";

export default function TasksPage() {
  const [user] = useCaminoStore((state) => [state.currentUser]);
  const [searchText, setSearchText] = useState("");
  const [taskStatusType, setTaskStatusType] = useState<TaskStatusType>("open");
  const { setBreadcrumbs, setPageTitle } = useNavbar();
  const [initialJourneys, setInitialJourneys] = useState<NewHireJourney[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const newHireId = searchParams.get("newHireId");

  const updateNewHireId = (id: string) => {
    if (id) {
      searchParams.set("newHireId", id);
    } else {
      searchParams.delete("newHireId");
    }
    setSearchParams(searchParams);
  };

  useEffect(() => {
    setPageTitle("My Tasks");
    setBreadcrumbs([
      {
        name: "My Tasks",
        isCurrentPage: true,
      },
    ]);
  }, [setBreadcrumbs, setPageTitle]);

  const setInitialNewHires = useCallback(
    (data: BaseTask[]) => {
      if ([0, 1].includes(initialJourneys.length) && data && data.length !== 0) {
        setInitialJourneys(pullUniqueNewHireJourneysFromTasks(data as Task[]));
      }
    },
    [initialJourneys.length],
  );

  return (
    <AppPage>
      <Card>
        <CardBody>
          <Stack direction="column" w="100%" alignItems="start" gap="2">
            <SearchBar
              minWidth="500"
              name="tasksSearch"
              onInput={setSearchText}
              border="2px solid var(--chakra-colors-border-default)"
            />
            <Flex align="center" gap="8">
              <NewHireJourneySelect
                newHireJourneys={initialJourneys}
                selectedNewHireJourneyId={newHireId}
                onChange={(e) => updateNewHireId(e as string)}
                placeholder="Select by New Hire"
                isClearable
                useBasicStyles
              />
              <SelectableTasksByStatus
                setTaskStatusType={setTaskStatusType}
                taskStatusType={taskStatusType}
              />
            </Flex>
          </Stack>
          <TasksTable
            displayNewHireName
            includeCommunications
            filterByUserId={user?.id}
            newHireJourneyId={newHireId as string}
            onDataSuccess={setInitialNewHires}
            searchText={searchText}
            statusFilter={taskStatusType}
          />
        </CardBody>
      </Card>
    </AppPage>
  );
}
