/* eslint-disable import/prefer-default-export */

import {
  Tooltip, Flex, Text, TextProps, Link,
} from "@chakra-ui/react";
import ReceiverLinkOrTBD from "components/Table/CellDisplayHelpers";
import { EMOJI_ICONS } from "definitions/constants/icons";
import { PEOPLE_TEAM_ROUTES, SHARED_ROUTES } from "definitions/constants/routeConstants";
import { ActionTriggerAndTimingText, ActionTriggerText } from "features/Action/DisplayInfo";
import { display12hrTimeOfDay, getLocalTimezone, isInPast, relativeDateTimeWithTimezone } from "helpers/time";
import { actionTargetText, actionableTypeConverter, emailTypeConverter } from "helpers/workflowActionableConverters";
import { AssignedAction } from "models/automation/scheduledWorkflow";
// needed to import moment.tz to get moment().tz to work
import "moment-timezone";
import moment from "moment";
import { Link as RouterLink, generatePath } from "react-router-dom";
import { DEFAULT_TIME_OF_DAY } from "definitions/constants/trigger";

interface AssignedActionDisplayProps extends TextProps {
  assignedAction: AssignedAction;
}

interface AssignedActionDisplayWithLinkProps extends AssignedActionDisplayProps {
  linkProps?: object;
}

export function AssignedActionTypeText({ assignedAction, ...rest }: AssignedActionDisplayProps) {
  const emailType = assignedAction?.actionType === "email" ? ` (${emailTypeConverter(assignedAction?.emailType)})` : "";
  const readableActionType = actionableTypeConverter(assignedAction);
  const emoji = EMOJI_ICONS[assignedAction?.actionType];
  const additionalText = assignedAction?.actionType === "task_notifier" || assignedAction?.actionType === "task_reminder" ? (
    <>
      {" "}
      for Task
      {" "}
      <Link as={RouterLink} to={{ pathname: generatePath(SHARED_ROUTES.tasks.show, { id: assignedAction.taskId }) }}>
        {assignedAction?.name}
      </Link>
    </>
  ) : (
    ""
  );
  return (
    <Text fontSize="md" textAlign="center" color="chakra-body-text" {...rest}>
      {`${emoji} `}
      <Text as="span" fontWeight="medium">
        {readableActionType}
      </Text>
      {emailType}
      {additionalText}
    </Text>
  );
}

export function AssignedActionOriginalAction({
  assignedAction,
  linkProps,
  ...rest
}: AssignedActionDisplayWithLinkProps) {
  return (
    <Text fontSize="md" textAlign="left" color="chakra-body-text" {...rest}>
      {`Created from Template `}
      <Text as="span" fontWeight="medium">
        <Link
          as={RouterLink}
          to={{
            pathname: generatePath(PEOPLE_TEAM_ROUTES.actions.show, { id: assignedAction?.workflowAction?.id || "" }),
          }}
          {...linkProps}
        >
          {assignedAction.workflowAction.actionable.name}
        </Link>
      </Text>
    </Text>
  );
}

AssignedActionOriginalAction.defaultProps = {
  linkProps: {},
};

export function AssignedActionTargetText({
  assignedAction,
  linkProps,
  ...rest
}: AssignedActionDisplayProps & { linkProps?: object }) {
  const additionalText = actionTargetText(assignedAction.workflowAction);
  return (
    <Text fontSize="sm" fontWeight="medium" textAlign="center" color="chakra-body-text" {...rest}>
      {`${EMOJI_ICONS.target} `}
      <ReceiverLinkOrTBD receiver={assignedAction?.targetable} {...linkProps} />
      {additionalText && ` (${additionalText})`}
    </Text>
  );
}

AssignedActionTargetText.defaultProps = {
  linkProps: {},
};

function scheduledAtOriginalTimezone(assignedAction: AssignedAction) {
  if (!assignedAction || assignedAction?.trigger?.type !== "date_time" || !assignedAction?.timezone) {
    return "";
  }
  if (assignedAction?.timezone === getLocalTimezone()) {
    return ` (${moment.tz(assignedAction?.timezone).format("z")})`;
  }
  return ` (${moment(assignedAction?.trigger?.data?.datetime).tz(assignedAction?.timezone).format("h:mm a z")})`;
}

function attemptedAtOriginalTimezone(assignedAction: AssignedAction) {
  if (!assignedAction || !assignedAction?.attemptedAt || !assignedAction?.timezone) {
    return "";
  }
  if (assignedAction?.timezone === getLocalTimezone()) {
    return ` (${moment.tz(assignedAction?.timezone).format("z")})`;
  }
  return ` (${moment(assignedAction?.attemptedAt).tz(assignedAction?.timezone).format("h:mm a z")})`;
}

function scheduledAtBackgroundColor(assignedAction: AssignedAction) {
  if (!assignedAction || ["processed", "skipped"].includes(assignedAction.status)) {
    return {};
  }
  const { trigger } = assignedAction;

  if (trigger.type === "dependency" && !assignedAction.attemptedAt) {
    return { bg: "sortaOrange.400" };
  } else if (isInPast(assignedAction.trigger?.data?.datetime)) {
    return { bg: "rose.500" };
  }
  return {};
}

export function AssignedActionScheduledAt({
  assignedAction,
  textProps,
}: {
  assignedAction: AssignedAction;
  textProps?: object;
}) {
  const { trigger } = assignedAction;

  if (assignedAction.status === "skipped") {
    return null;
  }

  if (
    assignedAction?.workflowAction?.actionable?.trigger?.data?.timezoneType === "target_timezone"
    && !assignedAction.targetable
  ) {
    const helperText = "Will Schedule At";
    const timeOfDay = assignedAction?.workflowAction?.actionable?.trigger?.data?.timeOfDay || DEFAULT_TIME_OF_DAY;
    const triggerText = `${display12hrTimeOfDay(timeOfDay)} (Recipient's Timezone)`;
    return (
      <Tooltip label={helperText} aria-label={helperText}>
        <Flex align="center" gap="2" px="1" borderRadius="sm">
          <Text fontSize="md" textAlign="center" color="chakra-body-text" {...textProps}>
            {`${EMOJI_ICONS.scheduledAt} ${triggerText}`}
          </Text>
        </Flex>
      </Tooltip>
    );
  }

  const attemptedAtWithOriginalTimezone = attemptedAtOriginalTimezone(assignedAction);
  const scheduledAtWithOriginalTimezone = scheduledAtOriginalTimezone(assignedAction);
  if (assignedAction.status === "processed") {
    return (
      <Tooltip label="Sent At" aria-label="Sent At">
        <Flex align="center" gap="2" px="1" borderRadius="sm">
          <Text fontSize="md" textAlign="center" color="chakra-body-text" {...textProps}>
            {`${EMOJI_ICONS.sentAt} ${relativeDateTimeWithTimezone(
              assignedAction?.attemptedAt,
            )}${attemptedAtWithOriginalTimezone}`}
          </Text>
        </Flex>
      </Tooltip>
    );
  }
  if (["error"].includes(assignedAction?.status)) {
    const scheduledText = assignedAction?.attemptedAt
      ? `${EMOJI_ICONS.failed} ${relativeDateTimeWithTimezone(assignedAction?.attemptedAt)}`
      : `${EMOJI_ICONS.scheduledAt} ${relativeDateTimeWithTimezone(assignedAction?.trigger?.data?.datetime)}`;
    const backgroundColor = assignedAction?.attemptedAt ? {} : scheduledAtBackgroundColor(assignedAction);
    const helperText = assignedAction?.attemptedAt ? "Attempted At" : "Scheduled At";
    const originalTimezone = assignedAction?.attemptedAt
      ? attemptedAtWithOriginalTimezone
      : scheduledAtWithOriginalTimezone;

    return (
      <Tooltip label={helperText} aria-label={helperText}>
        <Flex align="center" gap="2" px="1" borderRadius="sm" {...backgroundColor}>
          <Text fontSize="md" textAlign="center" color="chakra-body-text" {...textProps}>
            {`${scheduledText}${originalTimezone}`}
          </Text>
        </Flex>
      </Tooltip>
    );
  }
  const attempted = !!assignedAction?.attemptedAt;

  switch (trigger.type) {
    case "dependency":
      return (
        <Tooltip
          label={attempted ? "Attempted At" : "Scheduling TBD"}
          aria-label={attempted ? "Attempted At" : "Scheduling TBD"}
        >
          <Flex align="center" gap="2" {...scheduledAtBackgroundColor(assignedAction)} px="1" borderRadius="sm">
            <Text fontSize="md" textAlign="center" color="chakra-body-text" {...textProps}>
              {`${EMOJI_ICONS.scheduledAt} ${
                relativeDateTimeWithTimezone(assignedAction?.attemptedAt) || "tbd"
              }${attemptedAtWithOriginalTimezone}`}
            </Text>
          </Flex>
        </Tooltip>
      );
    case "date_time":
      return (
        <Tooltip label="Scheduled At" aria-label="Scheduled At">
          <Flex align="center" gap="2" {...scheduledAtBackgroundColor(assignedAction)} px="1" borderRadius="sm">
            <Text fontSize="md" textAlign="center" color="chakra-body-text" {...textProps}>
              {`${EMOJI_ICONS.scheduledAt} ${
                relativeDateTimeWithTimezone(assignedAction?.trigger?.data?.datetime) || ""
              }${scheduledAtWithOriginalTimezone}`}
            </Text>
          </Flex>
        </Tooltip>
      );
    default:
      return null;
  }
}

AssignedActionScheduledAt.defaultProps = {
  textProps: {},
};

export function assignedActionSentOrSkippedAt(assignedAction: AssignedAction) {
  const attemptedAtWithOriginalTimezone = attemptedAtOriginalTimezone(assignedAction);
  const scheduledAtWithOriginalTimezone = scheduledAtOriginalTimezone(assignedAction);

  if (assignedAction?.status === "processed") {
    return `${relativeDateTimeWithTimezone(assignedAction?.attemptedAt)}${attemptedAtWithOriginalTimezone}`;
  }

  if (assignedAction?.status === "skipped") {
    const scheduledText = assignedAction?.attemptedAt
      ? `${relativeDateTimeWithTimezone(assignedAction?.attemptedAt)}`
      : `${relativeDateTimeWithTimezone(assignedAction?.trigger?.data?.datetime)}`;
    const originalTimezone = assignedAction?.attemptedAt
      ? attemptedAtWithOriginalTimezone
      : scheduledAtWithOriginalTimezone;

    return `${scheduledText}${originalTimezone}`;
  }

  return null;
}

export function AssignedActionTriggerText({ assignedAction, ...rest }: { assignedAction: AssignedAction }) {
  if (assignedAction?.trigger?.data?.customized !== true) {
    return ActionTriggerText({ action: assignedAction?.workflowAction, ...rest });
  }
  return (
    <Text fontSize="md" textAlign="center" color="chakra-body-text" {...rest}>
      {`${EMOJI_ICONS.triggerAt} Custom Date & Time`}
    </Text>
  );
}

export function AssignedActionTriggerAndTimingText({ assignedAction, ...rest }: { assignedAction: AssignedAction }) {
  if (assignedAction?.trigger?.data?.customized !== true) {
    return ActionTriggerAndTimingText({ action: assignedAction?.workflowAction, ...rest });
  }
  return (
    <Text fontSize="md" textAlign="center" color="chakra-body-text" {...rest}>
      {`${EMOJI_ICONS.triggerAt} Custom Date & Time`}
    </Text>
  );
}
