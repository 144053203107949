import { Icon, IconProps, useColorMode, useColorModeValue } from "@chakra-ui/react";

export default function CaminoNewHireIcon(props: IconProps) {
  const color = useColorModeValue("#959393", "#FFFFFF");
  return (
    <Icon viewBox="-1 -3 16 18" {...props} fill="none">
      <path
        fill="#59C5EE"
        d="M7.16797 9.66699C7.96797 9.66699 10.668 9.66699 10.668
           10.667C8.66797 13.667 3.33447 11.5 2.83447 11C2.33447
           10.5 6.16797 9.66699 7.16797 9.66699Z"
      />
      <path
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66732 1.33333C8.08181 1.33333 9.43836
           1.89524 10.4386 2.89543C11.4387 3.89562
           12.0007 5.25218 12.0007 6.66667C12.0007
           7.36705 11.8627 8.06058 11.5947 8.70765C11.4523
           9.05131 11.2748 9.3783 11.0653 9.68359C9.69869
           9.01349 8.19407 8.66443 6.66681 8.66667C5.09016
           8.66675 3.59718 9.03202 2.26923 9.68349C2.05984
           9.37824 1.8823 9.05128 1.73996 8.70765C1.47194
           8.06058 1.33398 7.36705 1.33398 6.66667C1.33398
           5.25218 1.89589 3.89562 2.89608 2.89543C3.89628
           1.89524 5.25283 1.33333 6.66732 1.33333ZM1.55725
           10.9482C1.12226 10.4291 0.768185 9.84574 0.50812
           9.21789C0.173089 8.40905 0.000650406 7.54215
           0.000650406 6.66667C0.000650406 4.89856 0.703031
           3.20286 1.95327 1.95262C3.20352 0.702379 4.89921
           0 6.66732 0C8.43543 0 10.1311 0.702379 11.3814
           1.95262C12.6316 3.20286 13.334 4.89856 13.334
           6.66667C13.334 7.54215 13.1615 8.40905 12.8265
           9.21789C12.5665 9.84574 12.2124 10.4291 11.7774
           10.9482C11.7676 10.9607 11.7574 10.9728 11.7468
           10.9845C11.6306 11.1212 11.5087 11.2534 11.3814
           11.3807C10.7623 11.9998 10.0274 12.4908 9.21854
           12.8259C8.4097 13.1609 7.5428 13.3333 6.66732
           13.3333C5.79184 13.3333 4.92493 13.1609 4.11609
           12.8259C3.30726 12.4908 2.57233 11.9998 1.95327
           11.3807C1.82597 11.2534 1.70408 11.1212 1.58784
           10.9845C1.57727 10.9728 1.56707 10.9607 1.55725
           10.9482ZM3.20168 10.7205C4.26254 10.257 5.43425
           10 6.66732 10H6.66833C7.86372 9.99819 9.04311
           10.2449 10.1328 10.7207C9.70744 11.0843 9.22686
           11.3792 8.7083 11.594C8.06123 11.862 7.3677
           12 6.66732 12C5.96693 12 5.27341 11.862 4.62634
           11.594C4.10771 11.3792 3.62706 11.0842 3.20168
           10.7205ZM6.66681 8.66667C6.66664 8.66667 6.66648
           8.66667 6.66631 8.66667L6.66732 9.33333V8.66667C6.66715
           8.66667 6.66698 8.66667 6.66681 8.66667ZM6.66732
           4C7.02094 4 7.36008 4.14048 7.61013 4.39052C7.86018
           4.64057 8.00065 4.97971 8.00065 5.33333C8.00065
           5.68696 7.86018 6.02609 7.61013 6.27614C7.36008
           6.52619 7.02094 6.66667 6.66732 6.66667C6.31369
           6.66667 5.97456 6.52619 5.72451 6.27614C5.47446
           6.02609 5.33398 5.68696 5.33398 5.33333C5.33398
           4.97971 5.47446 4.64057 5.72451 4.39052C5.97456
           4.14048 6.31369 4 6.66732 4ZM8.55294 3.44772C8.05284
           2.94762 7.37456 2.66667 6.66732 2.66667C5.96007
           2.66667 5.2818 2.94762 4.7817 3.44772C4.2816 3.94781
           4.00065 4.62609 4.00065 5.33333C4.00065 6.04058 4.2816
           6.71885 4.7817 7.21895C5.2818 7.71905 5.96007 8 6.66732
           8C7.37456 8 8.05284 7.71905 8.55294 7.21895C9.05303
           6.71885 9.33398 6.04058 9.33398 5.33333C9.33398
           4.62609 9.05303 3.94781 8.55294 3.44772Z"
      />
    </Icon>
  );
}
