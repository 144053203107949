export const CONTENT_VARIABLES = [
  "name",
  "new_hire_info_packet_url",
  "benefits_info_url",
  "hris_url",
  "company_handbook_url",
  "company_wiki_url",
  "company_goals_url",
  "default_timezone",
  "default_trigger_time_of_day",
  "acronym_repository_url",
  "buddy_expectations_url",
  "buddy_selection_url",
  "onboarding_plan_templates_url",
  "offer_sign_date",
  "start_date",
  "onboarding_plan_url",
  "team_wiki_url",
  "team_goals_url",
  "title",
  "description",
  "status",
  "task_type",
  "due_date",
  "estimated_time",
  "first_name",
  "last_name",
  "full_name",
  "work_email",
  "title",
  "personal_email",
  "linkedin_url",
  "slack_mention",
  "avatar_url",
  "start_date_formatted",
  "offer_sign_date_formatted",
  "employment_type",
  "preferred_name",
  "country",
  "department",
  "phone_country_code",
  "phone_number",
  "work_mode",
  "shipping_tracking_number",
  "shipping_tracking_url",
  "shipping_carrier",
  "internal_onboarding_checklist_url",
  "hris_onboarding_url",
];

export const ISSUE_TYPE_OPTIONS = {
  TARGET_SLACK_USER_DOES_NOT_EXIST: "target_slack_user_does_not_exist",
  SLACK_NOT_CONNECTED: "slack_not_connected",
  BUDDY_HAS_NOT_BEEN_SELECTED: "buddy_has_not_been_selected",
  CHANNEL_NOT_FOUND: "channel_not_found",
  MANAGER_DOES_NOT_EXIST: "manager_does_not_exist",
  EMAIL_ADDRESS_MISSING: "email_address_missing",
  LINKEDIN_URL_IS_MISSING: "linkedin_url_is_missing",
  ACTION_IS_SCHEDULED_IN_THE_PAST: "action_is_scheduled_in_the_past",
  REFERENCE_EVENT_OVERLAP: "reference_event_overlap",
  ONBOARDING_PLAN_IS_MISSING: "onboarding_plan_is_missing",
  CONTENT_ISSUE: "template_rendering",
  UNPAUSED_AFTER_TRIGGER_DATE: "unpaused_after_trigger_date",
  MISSING_TARGET_ID: "missing_target_id",
  MISSING_MESSAGE_SERVICE_USER: "missing_message_service_user",
  MISSING_MESSAGE_SERVICE: "missing_message_service",
  MISSING_EMAIL_ADDRESS: "missing_email_address",
  UNDELIVERABLE: "undeliverable",
  TRIGGER_AT_IN_PAST: "trigger_at_in_past",
  UNDEFINED_TEMPLATE_NAMESPACE: "undefined_template_namespace",
};

export const RESOLUTION_OPTION_TYPES = {
  RE_SYNC_SLACK_CHANNELS: "re_sync_slack_channels",
  RE_SYNC_SLACK_USERS: "re_sync_slack_users",
  SKIP: "skip",
  RESCHEDULE_TO_TOMORROW: "reschedule_to_tomorrow",
  RESCHEDULE_NEXT_MONDAY: "reschedule_next_monday",
  CONNECT_SLACK: "connect_slack",
  CHECK_CAMINO_IS_ADDED_TO_SLACK_CHANNEL: "check_camino_is_added_to_slack_channel",
  RETRY: "retry",
  ADD_EMAIL_ADDRESS: "add_email_address",
  SEND_NOW: "send_now",
  REMIND_MANAGER_WITH_CUSTOM_MESSAGE: "remind_manager_with_custom_message",
  REMIND_PEOPLE_TEAM_CONTACT_WITH_CUSTOM_MESSAGE: "remind_people_team_contact_with_custom_message",
  UPDATE_MISSING_INFORMATION: "update_missing_information",
};
