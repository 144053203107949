import { Box, Stack } from "@chakra-ui/react";
import GreyBlackField, { HorizontalGreyBlackField } from "components/DataDisplay/GreyBlackField";
import { assignedActionSentOrSkippedAt } from "features/AssignedAction/DisplayInfo";
import EmbeddedHtml from "components/DataDisplay/EmbeddedHtml";
import { taskTargetText } from "helpers/workflowActionableConverters";
import { Task } from "models";
import { findTaskNotifier } from "features/Task/helpers";

export default function ProcessedTask({ task }: { task: Task }) {
  const taskNotifier = findTaskNotifier(task);
  const isSent = taskNotifier?.status === "processed";
  return (
    <Stack my="2" gap="6" direction="column" align="start">
      <HorizontalGreyBlackField size="lg" label={isSent ? "Sent" : "Skipped On"}>
        {assignedActionSentOrSkippedAt(taskNotifier)}
      </HorizontalGreyBlackField>
      <HorizontalGreyBlackField size="lg" label="To">
        {taskTargetText(task)}
      </HorizontalGreyBlackField>
      <GreyBlackField size="lg" label="Title">
        {task?.title}
      </GreyBlackField>
      <GreyBlackField size="lg" label="Body" w="100%">
        <Box borderRadius="md" p="2" w="100%" h="fill" border="1.5px solid var(--chakra-colors-tableUnderlineBorder-DEFAULT)">
          <EmbeddedHtml innerHtml={task?.description} />
        </Box>
      </GreyBlackField>
    </Stack>
  );
}
