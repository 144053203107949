import { Badge, BadgeProps, CircularProgress, CircularProgressLabel, IconButton } from "@chakra-ui/react";
import { assignedPathPathById } from "helpers/url";
import { BaseAssignedPath } from "models/automation";
import { FiEdit2 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

interface PathsTableStatus {
  path: BaseAssignedPath;
}

export function TaskCompletionStatus({ path }: PathsTableStatus) {
  const taskNotifiers = path.actions
    .filter((action) => action.actionType === "task_notifier")
    .map((action) => action.task)
    .filter((task) => task.status !== "skipped");
  const tasksNotifiedCount = taskNotifiers.filter((task) => !!task.notifiedAt).length;
  const tasksCompletedCount = taskNotifiers.filter((task) => !!task.completedAt).length;
  if (!tasksNotifiedCount) {
    return "No Tasks";
  }
  const value = 100 * (tasksCompletedCount / tasksNotifiedCount);
  const color = value > 70 ? "green.400" : "orange.400";
  const text = `${tasksCompletedCount}/${tasksNotifiedCount}`;
  return (
    <CircularProgress value={value} color={color}>
      <CircularProgressLabel>{text}</CircularProgressLabel>
    </CircularProgress>
  );
}

export function ActionSentStatus({ path }: PathsTableStatus) {
  const totalActions = path.actions.filter(
    (action) => action.actionType !== "task_reminder" && !["skipped", "removed"].includes(action.status),
  ).length;
  const totalSent = path.actions.filter((action) => action.status === "processed").length;
  if (!totalActions) {
    return "No Actions";
  }
  const value = 100 * (totalSent / totalActions);
  const color = value > 70 ? "green.400" : "orange.400";
  const text = `${totalSent}/${totalActions}`;
  return (
    <CircularProgress value={value} color={color}>
      <CircularProgressLabel>{text}</CircularProgressLabel>
    </CircularProgress>
  );
}

export default function HasError({ path, ...rest }: PathsTableStatus & BadgeProps) {
  const hasError = path.actions.some((action) => action.status === "error");
  if (!hasError) {
    return null;
  }

  return (
    <Badge
      variant="subtle"
      colorScheme="red"
      size="lg"
      mt="2"
      borderRadius="5px"
      {...rest}
    >
      Error
    </Badge>
  );
}

export function EditPathButton({ path }: PathsTableStatus) {
  const navigate = useNavigate();
  return (
    <IconButton
      icon={<FiEdit2 />}
      size="2xs"
      onClick={() => navigate(assignedPathPathById(path?.id))}
      variant="tertiary"
      aria-label="Edit experience"
    />
  );
}
