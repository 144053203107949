import { ButtonGroup, Flex, Heading } from "@chakra-ui/react";
import ButtonWithConfirm from "components/Button/ButtonWithConfirm";
import ProceedCancelButtons from "components/Button/ProceedCancelButtons";
import ProcessedEmail from "components/Forms/AssignedActions/Email/ProcessedEmail";
import UpdateEmailForm from "components/Forms/AssignedActions/Email/UpdateEmailForm";
import { useForceSendAssignedAction } from "features/AssignedAction/hooks";
import { useIssuesByActionIdQuery } from "features/Issues/hooks";
import { CombinedAction } from "models/automation/combinedAction";
import { useRef, useState } from "react";

const CONFIRM_SEND_NOW = {
  header: "Confirm you want to send this email now!",
  body: "This cannot be undone! Be sure you want to send this email now.",
  confirmButtonText: "Send Now",
  buttonText: "Send Now",
};

interface EmailDrawerFormProps {
  onClose: () => void;
  combinedAction: CombinedAction;
}

export default function EmailDrawerForm({ onClose, combinedAction }: EmailDrawerFormProps) {
  const formRef = useRef<any>(null); // Create a ref to access form methods
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const issues = useIssuesByActionIdQuery(combinedAction?.id, { enabled: !!combinedAction?.id });

  const handleSubmit = (onSuccessCallback: () => void) => {
    formRef.current?.handleSubmit(onSuccessCallback); // Call form submit from the ref
  };

  const { mutate: sendNow, isReady } = useForceSendAssignedAction({ action: combinedAction });

  if (!combinedAction) {
    return null;
  }

  return (
    <Flex direction="column">
      <Heading size="xs">{combinedAction.name}</Heading>
      {combinedAction && (
        ["processed", "skipped"].includes(combinedAction.status) ? (
          <ProcessedEmail assignedAction={combinedAction} />
        ) : (
          <>
            <UpdateEmailForm
              ref={formRef}
              assignedAction={combinedAction}
              onSuccess={onClose}
              setIsSubmitLoading={setIsSubmitLoading}
              setIsFormDirty={setIsFormDirty}
            />
            <ButtonGroup display="flex" justifyContent="flex-end">
              <ButtonWithConfirm
                intent="warning"
                isDisabled={!isReady}
                {...CONFIRM_SEND_NOW}
                handleConfirm={() => handleSubmit(() => sendNow())}
              />
              <ProceedCancelButtons
                handleSubmit={() => handleSubmit(() => {})}
                onClose={onClose}
                proceedText="Update Email"
                proceedDisabled={!isFormDirty}
                isLoading={isSubmitLoading}
              />
            </ButtonGroup>
          </>
        )
      )}
    </Flex>
  );
}
