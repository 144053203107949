import { Button } from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import { BaseAssignedAction } from "models/automation";
import ModalFormWrapper from "../ModalFormWrapper";
import ScheduledActionForm from ".";
import { DynamicActionType } from "components/ActionType";

interface ScheduledWorkflowActionButtonWithModalProps extends PropsWithChildren {
  scheduledAction: BaseAssignedAction | null;
}

export default function ScheduledActionButtonWithModal({
  scheduledAction,
  children,
}: ScheduledWorkflowActionButtonWithModalProps) {
  return (
    <ModalFormWrapper
      eid={scheduledAction?.id}
      size="5xl"
      modalHeader={
        <DynamicActionType actionType={scheduledAction.actionType} />
      }
      button={(children as JSX.Element) || <Button>{buttonLabel}</Button>}
    >
      <ScheduledActionForm scheduledAction={scheduledAction} />
    </ModalFormWrapper>
  );
}
