import {
  Flex,
  FormLabel,
  IconButton,
  Spacer,
  useDisclosure,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverTrigger,
  PopoverContent,
  PopoverAnchor,
  useColorModeValue,
  FormControl,
  Box,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { Control, useFieldArray } from "react-hook-form";
import { formInputWithValueBorder, floatingNoValueBorder, selectedInputBorder } from "definitions/constants/styling";
import { Schema } from "./schema";
import RecipientTag from "./RecipientTag";
import NewRecipientForm from "./NewRecipientForm";
import { EmailRecipient } from "models/automation";

type EligibleFields = "additionalRecipients" | "ccRecipients" | "bccRecipients";

export const floatingLabelStyles = {
  transform: "scale(0.75) translateY(-10px)",
};

export const floatingLabelBase = {
  top: "6px",
  fontSize: "md",
  left: 0,
  zIndex: 2,
  position: "absolute",
  pointerEvents: "none",
  mx: 3,
  px: 1,
  my: 2,
  transformOrigin: "left top",
};

export default function RecipientsContainer({
  label,
  name,
  control,
}: {
  label: string;
  name: EligibleFields;
  control: Control<Schema>;
}) {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });
  const { isOpen, onClose, getDisclosureProps, getButtonProps } = useDisclosure();

  const buttonProps = getButtonProps();
  const disclosureProps = getDisclosureProps();
  const bg = useColorModeValue("creamBg.500", "gray.600");

  return (
    <Popover matchWidth {...disclosureProps} onClose={onClose}>
      <PopoverAnchor>
        <Box width="100%">
          <FormControl id={name}>
            <Flex
              mt={4}
              mb={4}
              bg="transparent"
              borderRadius="lg"
              p={2}
              minH="52px"
              width="100%"
              height="100%"
              alignItems="center"
              {...floatingNoValueBorder}
              {...(isOpen ? { ...selectedInputBorder } : {})}
              {...(fields.length ? { ...formInputWithValueBorder } : {})}
              _hover={{
                ...selectedInputBorder,
              }}
            >
              <Flex height="100%" flexWrap="wrap" alignItems="center" pl="6px" pt="18px" gap={2}>
                {fields.map((recipient, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <RecipientTag key={index} recipient={recipient} remove={() => remove(index)} />
                ))}
              </Flex>
              <Spacer />
              <PopoverTrigger>
                <IconButton
                  size="xs"
                  variant="ghost"
                  colorScheme="gray"
                  aria-label={`Add ${label}`}
                  icon={<AddIcon />}
                  {...buttonProps}
                />
              </PopoverTrigger>
            </Flex>
            <FormLabel {...floatingLabelBase} {...(fields.length ? floatingLabelStyles : {})}>
              {label}
            </FormLabel>
          </FormControl>
        </Box>
      </PopoverAnchor>
      <PopoverContent bg={bg} width="100%" display={isOpen ? "block" : "none"}>
        <PopoverCloseButton />
        <PopoverBody>
          <NewRecipientForm
            onSubmit={(data: any) => {
              append(data);
              onClose();
            }}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

export function DisabledRecipientContainer({ recipients, ...rest }: { recipients: EmailRecipient[] }) {
  return (
    <Flex height="100%" flexWrap="wrap" alignItems="center" gap={2} {...rest}>
      {recipients.map((recipient, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <RecipientTag key={`recipient-${index}`} recipient={recipient} disabled />
      ))}
    </Flex>
  );
}
