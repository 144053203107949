import { Box, BoxProps, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

interface LoadingDotsProps extends BoxProps {
  textProps?: object;
}

function LoadingDots({ textProps = {}, ...rest }: LoadingDotsProps) {
  const [dots, setDots] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prev) => (prev.length < 3 ? `${prev}.` : "")); // Cycle through ., .., ...
    }, 800);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box display="flex" justifyContent="center" alignItems="center" {...rest}>
      <Text fontSize="xl" fontWeight="bold" color="fg.emphasized" {...textProps}>
        Loading
        {dots}
      </Text>
    </Box>
  );
}

export default LoadingDots;
