import { useQuery } from "@tanstack/react-query";
import { fromActionId } from "helpers/newHireJourney";
import { apiAssignedActionPathById, apiTaskPathById } from "helpers/url";
import { CombinedActionId } from "models/automation/combinedAction";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { Task } from "models";
import apiClient from "services/ApiClient";

export const combinedActionKeys = {
  all: ["combinedAction"] as const,
  newHires: () => [...combinedActionKeys.all, "newHires"] as const,
  newHire: (newHireJourneyId: string) => [...combinedActionKeys.newHires(), newHireJourneyId] as const,
  details: () => [...combinedActionKeys.all, "detail"] as const,
  detail: (id: string) => [...combinedActionKeys.details(), id] as const,
  batch: (ids: string[]) => [...combinedActionKeys.all, "batch", ids.sort().toString] as const,
};

export const useCombinedActionDetailQuery = (id: CombinedActionId, options = {}) => useQuery<AssignedAction>({
  queryKey: combinedActionKeys.detail(id),
  queryFn: async () => {
    const { actionType, id: actionId } = fromActionId(id);
    if (actionType === "task") {
      return apiClient.get(apiTaskPathById(actionId));
    }
    return apiClient.get(apiAssignedActionPathById(actionId));
  },
  staleTime: 1000 * 60 * 3, // 3 minutes
  ...options,
});
