/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import {
  Heading, Text, Icon, Card, CardBody, Skeleton, Flex, Tooltip, Box, FlexProps,
} from "@chakra-ui/react";
import { Link as RouterLink, generatePath } from "react-router-dom";
import { API_ROUTES, PEOPLE_TEAM_ROUTES } from "definitions/constants/routeConstants";
import {
  actionableTypeConverter,
  actionableTypeToIcon,
  assignedActionTargetText,
} from "helpers/workflowActionableConverters";
import { truncate } from "lodash";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { FiCheckSquare, FiSquare } from "react-icons/fi";
import { useQuery } from "@tanstack/react-query";
import { Task } from "models";
import apiClient from "services/ApiClient";
import { ASSIGNED_ACTION_STATUS_TYPES } from "definitions/constants";
import { HorizontalGreyBlackField } from "components/DataDisplay/GreyBlackField";
import { DISPLAY_INFO_LABEL_PROPS, DISPLAY_INFO_VALUE_PROPS } from "definitions/constants/styling";
import IssueExpander from "../IssueExpander";
import AssignedActionMenuButton from "../AssignedActionMenuButton";
import AssignedActionStatus from "../AssignedActionStatus";
import { AssignedActionScheduledAt } from "../DisplayInfo";

interface DependencyInfoProps extends FlexProps {
  assignedAction: AssignedAction;
  textProps?: object;
}

export function DependencyInfo({ assignedAction, textProps, ...rest }: DependencyInfoProps) {
  const { trigger } = assignedAction;

  const { data, isLoading, isError } = useQuery<Task>({
    queryKey: ["task", trigger?.data?.dependencyId],
    queryFn: () => apiClient.get<Task>(generatePath(API_ROUTES.tasks.show, { id: trigger?.data?.dependencyId || "" })),
    enabled: trigger.type === "dependency", // Enable query conditionally based on trigger type
  });

  if (trigger.type === "dependency" && isLoading) {
    return <Skeleton width="100px" height="24px" />; // Example of loading state
  }

  if (trigger.type === "dependency" && isError) {
    return <Text color="red">Error fetching task information</Text>; // Example of error handling
  }
  const isComplete = !isLoading && data?.status === "completed";

  return (
    <Flex ml="4" align="center" gap="2" {...rest}>
      {isComplete ? <Icon as={FiCheckSquare} color="green.500" /> : <Icon as={FiSquare} color="orange.500" />}
      <Text fontSize="md" textAlign="center" color="chakra-body-text" {...textProps}>
        Upon Task Completion:
        {" "}
        <span style={{ textDecoration: isComplete ? "line-through" : "none" }}>{data?.title}</span>
      </Text>
    </Flex>
  );
}

DependencyInfo.defaultProps = {
  textProps: {},
};

interface AssignedActionCardProps {
  rowData: AssignedAction;
  getCardProps: () => object;
}

export default function AssignedActionCard({
  rowData: assignedAction,
  getCardProps,
  ...rest
}: AssignedActionCardProps) {
  if (!assignedAction) {
    return <Skeleton height="100px" />;
  }
  const icon = actionableTypeToIcon({ actionType: assignedAction.actionType, taskType: assignedAction.taskType });
  const readableActionType = actionableTypeConverter({
    actionType: assignedAction.actionType,
    taskType: assignedAction.taskType,
  });
  const readableActionName = assignedAction.name.length >= 40
    ? truncate(assignedAction.name, { length: 43, omission: "..." })
    : assignedAction.name;
  const readableTarget = assignedActionTargetText(assignedAction);

  return (
    <Skeleton isLoaded={!!assignedAction} align="center">
      <Flex direction="column" maxW="1000px">
        <Flex bg="#FEEBC8" borderTopRadius="var(--card-radius)" pl="8" py="1">
          <Heading size="xxs" color="gray.700">
            Assigned Action
          </Heading>
        </Flex>
        <Card borderTopRadius="0" p="0" direction="row" boxShadow="md" mb="2" overflow="hidden" {...rest}>
          <Tooltip label={readableActionType} aria-label={`Action Type ${readableActionType}`}>
            <Flex
              padding="4"
              objectFit="cover"
              direction="column"
              align="center"
              justify="center"
              bg="brand.400"
              width="100px"
            >
              <Icon as={icon} w={8} h={8} color="white" />
              <Text fontSize="md" fontWeight="medium" textAlign="center" color="white" whiteSpace="normal">
                {readableActionType}
              </Text>
            </Flex>
          </Tooltip>
          <CardBody minW="600px" maxW="1000px" display="flex" p="4">
            <Flex direction="column" align="flex-start" width="100%">
              <Flex justify="space-between" width="100%">
                <Flex gap="4" align="center">
                  <RouterLink to={generatePath(PEOPLE_TEAM_ROUTES.assignedActions.show, { id: assignedAction.id })}>
                    <Heading size="xs" textAlign="start">
                      {readableActionName}
                    </Heading>
                  </RouterLink>
                  <AssignedActionStatus assignedAction={assignedAction} />
                </Flex>
                {assignedAction.status !== ASSIGNED_ACTION_STATUS_TYPES.PROCESSED && (
                  <AssignedActionMenuButton assignedAction={assignedAction} />
                )}
              </Flex>
              <Flex mt="1" align="center" gap={8}>
                <Tooltip label="Recipient" aria-label="Recipient">
                  <HorizontalGreyBlackField
                    label="To"
                    ml="4"
                  >
                    {readableTarget}
                  </HorizontalGreyBlackField>
                </Tooltip>
                <AssignedActionScheduledAt assignedAction={assignedAction} />
              </Flex>
              {assignedAction.trigger.type === "dependency" && (
                <DependencyInfo mt="2" assignedAction={assignedAction} />
              )}
              <IssueExpander mt="2" assignedAction={assignedAction} />
            </Flex>
          </CardBody>
        </Card>
      </Flex>
    </Skeleton>
  );
}
