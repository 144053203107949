import { useColorModeValue, Icon, IconProps } from "@chakra-ui/react";

export default function CaminoBuddyIcon(props: IconProps) {
  const color = useColorModeValue("#959393", "#FFFFFF");
  return (
    <Icon viewBox="0 0 17 16" {...props}>
      <path
        fill="#C6E2D3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 14C14 11.7909 12.2091 10 10 10C7.79086 10 6 11.7909 6 14H14Z"
      />
      <path
        fill={color}
        d="M13.2681 10.9363C12.8652 10.5449 12.3943 10.2254
           11.8772 9.99255C12.6065 9.41912 13.0717 8.54412
           13.0717 7.56287C13.0717 5.83162 11.5842 4.4113
           9.80049 4.43787C8.04415 4.46443 6.6291 5.85349
           6.6291 7.56287C6.6291 8.54412 7.09595 9.41912
           7.8236 9.99255C7.30637 10.2253 6.83551 10.5447
           6.43269 10.9363C5.55372 11.791 5.05467 12.9191
           5.02247 14.1222C5.02204 14.1389 5.02505 14.1555
           5.03133 14.171C5.03761 14.1866 5.04703 14.2007
           5.05903 14.2127C5.07103 14.2246 5.08538 14.2341
           5.10122 14.2406C5.11706 14.247 5.13407 14.2504
           5.15126 14.2504H6.05277C6.12199 14.2504 6.17995
           14.1972 6.18156 14.1301C6.21215 13.2238 6.59046
           12.3754 7.25533 11.7316C7.59555 11.3996 8.00023
           11.1365 8.44595 10.9573C8.89168 10.7781 9.36958
           10.6866 9.85201 10.6879C10.8324 10.6879 11.7548
           11.0582 12.4487 11.7316C13.1119 12.3754 13.4903
           13.2238 13.5225 14.1301C13.5241 14.1972 13.582
           14.2504 13.6512 14.2504H14.5528C14.5699 14.2504
           14.587 14.247 14.6028 14.2406C14.6186 14.2341
           14.633 14.2246 14.645 14.2127C14.657 14.2007
           14.6664 14.1866 14.6727 14.171C14.679 14.1555
           14.682 14.1389 14.6816 14.1222C14.6494 12.9191
           14.1503 11.791 13.2681 10.9363ZM9.85201 9.56287C9.30144
           9.56287 8.78307 9.35505 8.3951 8.97693C8.20039 8.78944
           8.04659 8.5658 7.94287 8.31937C7.83915 8.07293 7.78764
           7.80874 7.79141 7.54255C7.79624 7.03005 8.00712 6.53474
           8.37578 6.16755C8.76214 5.78318 9.2789 5.56912 9.83108
           5.56287C10.3768 5.55818 10.9065 5.76443 11.296
           6.13474C11.6953 6.51443 11.9142 7.02224 11.9142
           7.56287C11.9142 8.09724 11.7001 8.5988 11.3105
           8.97693C11.1194 9.16333 10.8921 9.31109 10.6417
           9.41167C10.3914 9.51224 10.123 9.56363 9.85201
           9.56287ZM5.81934 7.97537C5.80485 7.83943 5.79681
           7.70193 5.79681 7.56287C5.79681 7.31443 5.82095
           7.07224 5.86603 6.8363C5.8773 6.78005 5.84671
           6.72224 5.79359 6.6988C5.57465 6.60349 5.37342
           6.47224 5.19955 6.30662C4.99468 6.11382 4.83347
           5.88168 4.7262 5.62503C4.61893 5.36837 4.56796
           5.09283 4.57654 4.81599C4.59103 4.31443 4.7987
           3.83787 5.16092 3.47849C5.55855 3.08318 6.09302
           2.86755 6.66129 2.8738C7.17483 2.87849 7.67067
           3.07068 8.04576 3.4113C8.17294 3.52693 8.28241
           3.65505 8.37417 3.79255C8.40637 3.84099 8.46915
           3.8613 8.52389 3.84255C8.80722 3.74724 9.10665
           3.68005 9.41413 3.6488C9.50428 3.63943 9.5558
           3.54568 9.51555 3.46755C8.99235 2.46287 7.92341
           1.76912 6.68705 1.75037C4.90173 1.7238 3.41423
           3.14412 3.41423 4.8738C3.41423 5.85505 3.87948
           6.73005 4.60874 7.30349C4.09681 7.53318 3.62512
           7.85037 3.21622 8.24724C2.33402 9.10193 1.83497
           10.2301 1.80277 11.4347C1.80234 11.4514 1.80536
           11.468 1.81164 11.4835C1.81792 11.4991 1.82734
           11.5132 1.83934 11.5252C1.85134 11.5371 1.86568
           11.5466 1.88152 11.5531C1.89736 11.5595 1.91438
           11.5629 1.93156 11.5629H2.83469C2.90391 11.5629
           2.96186 11.5097 2.96347 11.4426C2.99406 10.5363
           3.37238 9.68787 4.03724 9.04412C4.51054 8.58474
           5.09008 8.26599 5.72275 8.1113C5.78554 8.09568
           5.82739 8.03787 5.81934 7.97537Z"
      />
    </Icon>
  );
}
