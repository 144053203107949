import { Heading, VStack } from "@chakra-ui/react";
import { ExtendedNewHireJourney } from "models/newHire";
import UserUpdateForm from "components/Forms/UserUpdateForm";

interface FieldsTabProps {
  newHireJourney: ExtendedNewHireJourney;
}

export default function FieldsTab({ newHireJourney }: FieldsTabProps) {
  return (
    <VStack align="flex-start">
      <Heading as="h6" size="xxs">
        Onboarding Information
      </Heading>
      <UserUpdateForm
        width="750px"
        userId={newHireJourney.user.id}
        fields={[
          "firstName",
          "lastName",
          "title",
          "preferredName",
          "pronouns",
          "personalEmail",
          "workEmail",
          "linkedinUrl",
          "location",
          "timezone",
          "department",
          "employmentType",
          "phoneNumber",
          "workMode",
          "internalOnboardingChecklistUrl",
          "onboardingPlanUrl",
          "shippingTrackingNumber",
          "shippingTrackingUrl",
          "shippingCarrier",
          "hrisOnboardingUrl",
        ]}
      />
    </VStack>
  );
}
