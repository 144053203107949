import { ButtonGroup, Flex, Heading } from "@chakra-ui/react";
import ButtonWithConfirm from "components/Button/ButtonWithConfirm";
import ProceedCancelButtons from "components/Button/ProceedCancelButtons";
import { HorizontalGreyBlackField } from "components/DataDisplay/GreyBlackField";
import ProcessedChat from "components/Forms/AssignedActions/Chat/ProcessedChat";
import UpdateChatForm from "components/Forms/AssignedActions/Chat/UpdateChatForm";
import { useForceSendAssignedAction } from "features/AssignedAction/hooks";
import { useIssuesByActionIdQuery } from "features/Issues/hooks";
import { titleize } from "helpers/string";
import { CombinedAction } from "models/automation/combinedAction";
import { useRef, useState } from "react";

const CONFIRM_SEND_NOW = {
  header: "Confirm you want to send this message now!",
  body: "This cannot be undone! Be sure you want to send this message now.",
  confirmButtonText: "Send Now",
  buttonText: "Send Now",
};

interface ChatMessageDrawerFormProps {
  onClose: () => void;
  combinedAction: CombinedAction;
}

export default function ChatMessageDrawerForm({ onClose, combinedAction }: ChatMessageDrawerFormProps) {
  const formRef = useRef<any>(null); // Create a ref to access form methods
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const { data: issues} = useIssuesByActionIdQuery(combinedAction?.id, { enabled: !!combinedAction?.id });

  const handleSubmit = (onSuccessCallback: () => void) => {
    formRef.current?.handleSubmit(onSuccessCallback); // Call form submit from the ref
  };

  const { mutate: sendNow, isReady } = useForceSendAssignedAction({ action: combinedAction });

  return (
    <Flex direction="column">
      <Heading size="2xs">{combinedAction.name}</Heading>
      {combinedAction && (
        ["processed", "skipped"].includes(combinedAction.status) ? (
          <ProcessedChat assignedAction={combinedAction} />
        ) : (
          <>
            <UpdateChatForm
              ref={formRef}
              assignedAction={combinedAction}
              onSuccess={onClose}
              setIsSubmitLoading={setIsSubmitLoading}
              setIsFormDirty={setIsFormDirty}
            />
            <ButtonGroup display="flex" justifyContent="flex-end">
              <ButtonWithConfirm
                intent="warning"
                isDisabled={!isReady}
                {...CONFIRM_SEND_NOW}
                handleConfirm={() => handleSubmit(() => sendNow())}
              />
              <ProceedCancelButtons
                handleSubmit={() => handleSubmit(() => {})}
                onClose={onClose}
                proceedText="Update Message"
                proceedDisabled={!isFormDirty}
                isLoading={isSubmitLoading}
              />
            </ButtonGroup>
          </>
        )
      )}
    </Flex>
  );
}
