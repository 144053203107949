import { Box, Stack } from "@chakra-ui/react";
import { DisabledRecipientContainer } from "components/Forms/PathActions/Email/RecipientsContainer";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import GreyBlackField, { HorizontalGreyBlackField } from "components/DataDisplay/GreyBlackField";
import { assignedActionSentOrSkippedAt } from "features/AssignedAction/DisplayInfo";
import EmbeddedHtml from "components/DataDisplay/EmbeddedHtml";
import { assignedActionTargetText } from "helpers/workflowActionableConverters";

export default function ProcessedEmail({ assignedAction }: { assignedAction: AssignedAction }) {
  const isSent = assignedAction?.status === "processed";
  return (
    <Stack my="2" gap="6" direction="column" align="start">
      <HorizontalGreyBlackField size="lg" label={isSent ? "Sent" : "Skipped On"}>
        {assignedActionSentOrSkippedAt(assignedAction)}
      </HorizontalGreyBlackField>
      <HorizontalGreyBlackField size="lg" label="To">
        {assignedActionTargetText(assignedAction)}
      </HorizontalGreyBlackField>
      {assignedAction?.additionalRecipients?.length && (
        <GreyBlackField size="lg" label="Additional Recipients">
          <DisabledRecipientContainer recipients={assignedAction?.additionalRecipients} />
        </GreyBlackField>
      )}
      {assignedAction?.ccRecipients?.length && (
        <GreyBlackField size="lg" label="cc">
          <DisabledRecipientContainer recipients={assignedAction?.ccRecipients} />
        </GreyBlackField>
      )}
      {assignedAction?.bccRecipients?.length && (
        <GreyBlackField size="lg" label="bcc">
          <DisabledRecipientContainer recipients={assignedAction?.bccRecipients} />
        </GreyBlackField>
      )}
      <GreyBlackField size="lg" label="Subject">
        {assignedAction?.content?.subject}
      </GreyBlackField>
      <GreyBlackField size="lg" label="Body" w="100%">
        <Box borderRadius="md" p="2" w="100%" h="fill" border="1.5px solid var(--chakra-colors-tableUnderlineBorder-DEFAULT)">
          <EmbeddedHtml innerHtml={assignedAction?.content?.body} />
        </Box>
      </GreyBlackField>
    </Stack>
  );
}
