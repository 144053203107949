import { useToast } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "definitions/constants";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { on } from "events";
import { useAssignedActionBatchQuery } from "features/AssignedAction/hooks";
import { NewHireFormValues } from "features/PendingNewHire/Form/definition";
import { useTaskBatchQuery } from "features/Task/hooks";
import { toActionId } from "helpers/newHireJourney";
import { apiAssignedPathsPathByNewHireId, apiNewHireJourneyPathById } from "helpers/url";
import { pick, uniq, uniqBy } from "lodash";
import { ExtendedNewHireJourney } from "models";
import { NewHireJourney } from "models/newHire";
import { User } from "models/user";
import { useCallback, useMemo } from "react";
import { generatePath } from "react-router-dom";
import apiClient from "services/ApiClient";

export const newHireJourneyKeys = {
  all: ["newHireJourneys"] as const,
  lists: () => [...newHireJourneyKeys.all, "list"] as const,
  list: (filters: string) => [...newHireJourneyKeys.lists(), { filters }] as const,
  details: () => [...newHireJourneyKeys.all, "detail"] as const,
  detail: (id: string) => [...newHireJourneyKeys.details(), id] as const,
};

const useNewHireJourneyQueryArgs = (id: string, options = {}) => ({
  queryKey: newHireJourneyKeys.detail(id),
  queryFn: async () => apiClient.get(apiNewHireJourneyPathById(id)),
  staleTime: 1000 * 60 * 3, // 3 minutes
  ...options,
});

export const useNewHireJourneyDetailQuery = (id: string, options = {}) => useQuery<ExtendedNewHireJourney>({
  ...useNewHireJourneyQueryArgs(id, options),
});

export const usePreLoadNewHireAssociations = (newHireJourney: ExtendedNewHireJourney, options = {}) => {
  const assignedActionIds = useMemo(
    () => uniq((newHireJourney?.assignedPaths || [])
      .flatMap((path) => path.actions)
      .filter((action) => action.actionType === "email" || action.actionType === "chat")
      .map((action) => toActionId(action))),
    [newHireJourney?.assignedPaths],
  );
  const taskIds = useMemo(
    () => uniq((newHireJourney?.tasks || []).map((task) => toActionId(task))),
    [newHireJourney?.tasks],
  );
  useTaskBatchQuery(taskIds, { enabled: !!taskIds.length });
  useAssignedActionBatchQuery(assignedActionIds, { enabled: !!assignedActionIds.length });
};

export const usePreloadNewHireJourneyDetailQuery = (id: string, options = {}) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery({ ...useNewHireJourneyQueryArgs(id), ...options });
};

export const useNewHiresListQuery = () => useQuery({
  queryKey: newHireJourneyKeys.lists(),
  queryFn: async () => apiClient.get(generatePath(API_ROUTES.newHireJourneys.base)),
});

type UserKeys = keyof User;

export const USER_FIELDS: UserKeys[] = [
  "firstName",
  "lastName",
  "preferredName",
  "title",
  "workEmail",
  "personalEmail",
  "pronouns",
  "timezone",
  "location",
  "rawLocation",
  "googleLocationId",
  "country",
  "department",
  "bio",
  "linkedinUrl",
  "managerId",
  "phoneCountryCode",
  "phoneNumber",
  "employmentType",
];

type NewHireJourneyKeys = keyof ExtendedNewHireJourney | "pending";

export const NEW_HIRE_JOURNEY_FIELDS: NewHireJourneyKeys[] = [
  "buddyId",
  "peopleTeamContactId",
  "startDate",
  "onboardingPlanUrl",
  "teamWikiUrl",
  "teamGoalsUrl",
  "pending",
  "shippingTrackingNumber",
  "shippingTrackingUrl",
  "shippingCarrier",
  "internalOnboardingChecklistUrl",
  "hrisOnboardingUrl",
  "workflowsStatus",
];

export function useUpdateNewHireJourney(id: string) {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation({
    mutationFn: (data: Partial<NewHireFormValues>) => {
      const user = pick(data, USER_FIELDS);
      const onboardingJourney = pick(data, NEW_HIRE_JOURNEY_FIELDS);
      const workflows = data?.workflowIds ? { workflowIds: data.workflowIds } : {};
      return apiClient.put<NewHireJourney>(generatePath(API_ROUTES.newHireJourneys.show, { id: id || "" }), {
        ...(Object.keys(user).length ? { user } : {}),
        ...(Object.keys(onboardingJourney).length ? { onboardingJourney } : {}),
        ...(Object.keys(workflows).length ? { workflows } : {}),
      });
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.newHireJourneysPending, res.id] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.newHireJourneysPendingCount] });
      queryClient.setQueryData(newHireJourneyKeys.detail(res.id), (oldData: NewHireJourney | undefined) => {
        if (!oldData) {
          return res;
        }
        return {
          ...oldData,
          ...res,
        };
      });
    },
    onError: (e) => {
      console.log(e);
      toast({
        title: "Error",
        description: "There was an error, please try again.",
        status: "error",
      });
    },
  });
}

export function useAddPathsToNewHireJourney(id: string) {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation({
    mutationFn: (data: Partial<NewHireFormValues>) => {
      const workflows = data?.workflowIds ? { workflowIds: data.workflowIds } : {};
      return apiClient.put<NewHireJourney>(apiAssignedPathsPathByNewHireId(id), {
        ...(Object.keys(workflows).length ? { workflows } : {}),
      });
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: newHireJourneyKeys.detail(res.id) });
    },
    onError: (e) => {
      console.log(e);
      toast({
        title: "Error",
        description: "There was an error, please try again.",
        status: "error",
      });
    },
  });
}

export function useUpdateNewHireJourneyStatus(newHireJourneyId: string) {
  const toast = useToast();
  const mutation = useUpdateNewHireJourney(newHireJourneyId);
  const { mutate, ...rest } = mutation;

  const toggle = useCallback((workflowsStatus: "enabled" | "disabled" | "completed") => {
    const newStatus = workflowsStatus === "enabled" ? "disabled" : "enabled";
    mutate({ workflowsStatus: newStatus }, {
      onSuccess: (res) => {
        toast({ title: `Automations ${res?.workflowsStatus === "disabled" ? "paused" : "running"}` });
      },
    });
  }, [mutate, toast]);

  return { ...rest, toggle };
}
