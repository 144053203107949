import { MenuButtonProps, MenuProps } from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import { BsThreeDots, BsThreeDotsVertical } from "react-icons/bs";
import IconMenu from "./IconMenu";

interface ThreeDotMenuProps extends MenuButtonProps, PropsWithChildren {
  menuProps?: Omit<MenuProps, "children">;
  ariaLabel?: string;
  inPortal?: boolean;
  size?: string;
}

export function HThreeDotMenu({
  menuProps = {}, inPortal = false, ariaLabel = "Options", children, ...rest
}: ThreeDotMenuProps) {
  return (
    <IconMenu inPortal={inPortal} menuProps={menuProps} ariaLabel={ariaLabel as string} icon={BsThreeDots} {...rest}>
      {children}
    </IconMenu>
  );
}

export function VThreeDotMenu({
  menuProps = {}, inPortal = false, ariaLabel = "Options", children, ...rest
}: ThreeDotMenuProps) {
  return (
    <IconMenu
      inPortal={inPortal}
      menuProps={menuProps}
      ariaLabel={ariaLabel as string}
      icon={BsThreeDotsVertical}
      {...rest}
    >
      {children}
    </IconMenu>
  );
}
