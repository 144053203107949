import { List, ListItem, ListIcon } from "@chakra-ui/react";
import { NewHireJourney } from "models/newHire";
import { ExtendedNewHireJourney } from "models";
import { MdCheckCircle, MdRemoveCircle } from "react-icons/md";

interface NewHireJourneyProps {
  newHireJourney: NewHireJourney | ExtendedNewHireJourney;
}

export default function NewHireJourneyChecklist({ newHireJourney }: NewHireJourneyProps) {
  return (
    <List>
      {[
        { complete: newHireJourney.buddy !== null, label: "Buddy Assigned" },
        { complete: !!newHireJourney.onboardingPlanUrl, label: "Personalized Onboarding Plan" },
        { complete: ["enabled", "completed"].includes(newHireJourney.workflowsStatus), label: "Automations Running" },
      ].map(({ complete, label }) => (
        <ListItem key={`new-hire-info-card-li-${label.toLowerCase().replace(/\s/, "-")}`}>
          <ListIcon
            key={label}
            as={complete ? MdCheckCircle : MdRemoveCircle}
            color={complete ? "green.500" : "red.500"}
          />
          {label}
        </ListItem>
      ))}
    </List>
  );
}
