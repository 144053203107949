/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import {
  Text,
  CardBody,
  Skeleton,
  Flex,
  Tag,
  Wrap,
  WrapItem,
  Box,
  TagProps,
  LinkBox,
  LinkOverlay,
  Button,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { relativeDate } from "helpers/time";
import { AssignedPath } from "models/automation/scheduledWorkflow";
import { newHireJourneyPath, assignedPathPath } from "helpers/url";
import ExternalLink from "components/Link";
import { CaminoHeading } from "components/Heading";
import { CardField } from "components/DataDisplay/Card";
import CaminoCalendar from "components/Icon/CaminoCalendar";
import CaminoManager from "components/Icon/CaminoManager";
import CaminoBuddy from "components/Icon/CaminoBuddy";
import CaminoLink from "components/Icon/CaminoLink";
import { BlueBorderCardWithHover } from "components/Card/BlueBorderCard";
import { ExtendedNewHireJourney } from "models";
import {
  AddMissingManagerButton,
  AddMissingBuddyButton,
  AddMissingPeopleTeamContactButton,
  AddMissingOnboardingPlanButton,
} from "features/Issues/Button/MissingNamespace";
import { MAX_CARD_WIDTH } from "definitions/constants";

interface ClickablePathTagProps extends TagProps {
  assignedPath: AssignedPath;
}

function ClickablePathTag({ assignedPath, ...rest }: ClickablePathTagProps) {
  return (
    <RouterLink to={assignedPathPath(assignedPath)}>
      <Tag size="lg" variant="path" {...rest}>
        {assignedPath.workflow.name}
      </Tag>
    </RouterLink>
  );
}

interface AddMissingOnboardingItemsProps {
  newHireJourney: ExtendedNewHireJourney;
}

function AddMissingOnboardingItems({ newHireJourney }: AddMissingOnboardingItemsProps) {
  return (
    <Flex gap="2" align="center">
      {newHireJourney && !newHireJourney?.manager?.id && <AddMissingManagerButton newHireJourney={newHireJourney} />}
      {newHireJourney && !newHireJourney?.buddyId && <AddMissingBuddyButton newHireJourney={newHireJourney} />}
      {newHireJourney && !newHireJourney?.peopleTeamContactId && (
        <AddMissingPeopleTeamContactButton newHireJourney={newHireJourney} />
      )}
      {newHireJourney && !newHireJourney?.onboardingPlanUrl && (
        <AddMissingOnboardingPlanButton newHireJourney={newHireJourney} />
      )}
    </Flex>
  );
}

interface NewHireJourneyCardProps {
  rowData: ExtendedNewHireJourney;
  getCardProps: () => object;
}

export default function NewHireJourneyCard({
  rowData: newHireJourney,
  getCardProps,
  ...rest
}: NewHireJourneyCardProps) {
  if (!newHireJourney) {
    return <Skeleton height="100px" />;
  }
  return (
    <LinkBox
      as={BlueBorderCardWithHover}
      p="0"
      direction="row"
      boxShadow="md"
      mb="2"
      maxW={MAX_CARD_WIDTH}
      w="100%"
      overflow="hidden"
      {...rest}
    >
      <CardBody minW="600px" display="flex" py="7" px="9">
        <Flex direction="column" align="flex-start" gap="2" width="100%">
          <Flex justify="space-between" align="center" width="100%">
            <CaminoHeading textAlign="start">
              <LinkOverlay as={RouterLink} to={newHireJourneyPath(newHireJourney)}>
                {`${newHireJourney?.user.fullName}`}
              </LinkOverlay>
            </CaminoHeading>
            <AddMissingOnboardingItems newHireJourney={newHireJourney} />
          </Flex>
          <Flex align="center" gap={16}>
            <CardField icon={CaminoCalendar} label="Start Date">
              {`${relativeDate(newHireJourney?.startDate)}`}
            </CardField>
            {newHireJourney.user?.manager?.fullName && (
              <CardField icon={CaminoManager} label="Manager">
                {newHireJourney.user?.manager?.fullName}
              </CardField>
            )}
            {newHireJourney.buddy?.fullName && (
              <CardField icon={CaminoBuddy} label="Buddy">
                {newHireJourney.buddy?.fullName}
              </CardField>
            )}
            {newHireJourney.onboardingPlanUrl && (
              <CardField icon={CaminoLink} label="Onboarding Plan">
                <ExternalLink
                  to={newHireJourney.onboardingPlanUrl}
                  linkProps={{ sx: { "--link-color": "var(--chakra-colors-linkBlue)" }, colorScheme: "linkBlue" }}
                  text="View"
                  textAlign="start"
                />
              </CardField>
            )}
          </Flex>
        </Flex>
      </CardBody>
    </LinkBox>
  );
}
