import {
  Box, HStack, Heading, Stack, Tag, useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { API_ROUTES } from "definitions/constants/routeConstants";
import apiClient from "services/ApiClient";
import { useQueryClient } from "@tanstack/react-query";
import ProceedCancelButtons from "components/Button/ProceedCancelButtons";
import { useEffect, useState } from "react";
import { generatePath } from "react-router-dom";
import { BaseAssignedAction } from "models/automation";
import { QUERY_KEYS } from "definitions/constants";
import { scheduledActionToStatusColor, scheduledActionStatusConverter } from "helpers/scheduledWorkflowConverters";
import ActionRichTextAreaField from "components/FormElements/Fields/ActionRichTextAreaField";
import SingleSelectField from "components/FormElements/Fields/SelectFields/SingleSelectField";
import TextInputField from "components/FormElements/Fields/InputFields/TextInputField";
import DatePickerField from "components/FormElements/Fields/DatePickerField";
import { Schema, formSchema as schema } from "./schema";
import {
  BODY_FIELD,
  EMAIL_TYPE_FIELD,
  MESSAGE_FIELD,
  NAME_FIELD,
  SUBJECT_FIELD,
  TRIGGER_DATE_TIME_FIELD,
} from "./definitions";

const values = (action: BaseAssignedAction): Schema => ({
  body:
    action.actionType === "email" || action.actionType === "chat"
      ? (action?.content?.bodyTrix ?? action?.content?.body)
      : undefined,
  emailType: action.actionType === "email" ? action?.emailType : undefined,
  message: action.actionType === "task_reminder" ? action?.content?.message : undefined,
  name: action?.name,
  subject: action.actionType === "email" ? action?.content?.subject : undefined,
  actionType: action?.actionType,
  trigger: action?.trigger,
});

interface ScheduledActionFormProps {
  scheduledAction: BaseAssignedAction | null;
  onClose?: () => void;
}

export default function ScheduledActionForm({ scheduledAction, onClose = undefined }: ScheduledActionFormProps) {
  const isUpdate = !!scheduledAction;

  let defaultValues: Partial<Schema>;
  if (isUpdate) {
    defaultValues = values(scheduledAction);
  } else {
    defaultValues = {
      actionType: "email",
      emailType: "work_email",
    };
  }

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    register,
    reset,
    setFocus,
    watch,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver<Schema>(schema),
    defaultValues,
  });
  const toast = useToast({
    duration: 9000,
    isClosable: true,
    position: "top",
  });
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  /* HandleOnSubmit */
  const onSubmit = async (data: Schema) => {
    const {
      emailType, subject, body, message, ...rest
    } = data;
    const formData = {
      ...rest,
      emailType,
      content: {
        subject,
        body,
        message,
      },
    };

    try {
      setIsLoading(true);
      const res = isUpdate
        ? await apiClient.put<BaseAssignedAction>(
          generatePath(API_ROUTES.assignedActions.show, { id: scheduledAction?.id }),
          formData,
        )
        : await apiClient.post<BaseAssignedAction>(API_ROUTES.assignedActions.base, formData);
      setIsLoading(false);
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.assignedActions, scheduledAction?.id] });
      console.log(res);
      toast({
        title: isUpdate ? "Action Updated." : "New Action Created.",
        status: "success",
      });
      onClose?.();
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      toast({
        title: "Error",
        description: "There was an error, please try again.",
        status: "error",
      });
    }
  };

  useEffect(() => {
    setFocus("name");
    reset();
  }, [reset, setFocus]);

  const [actionType, trigger] = watch(["actionType", "trigger"]);
  const proceedText = isUpdate ? "Update Action" : "Create Action";
  const targetType = scheduledAction?.workflowAction.actionable.targetType;

  return (
    <>
      <Stack alignItems="start">
        {scheduledAction && (
          <Tag
            textAlign="center"
            paddingY="4px"
            size="md"
            variant="solid"
            colorScheme={scheduledActionToStatusColor(scheduledAction)}
          >
            {scheduledActionStatusConverter(scheduledAction?.status)}
          </Tag>
        )}

        {actionType === "email" && (
          <Box width="30%" alignItems="start">
            <SingleSelectField isClearable={false} required {...EMAIL_TYPE_FIELD} errors={errors} control={control} />
          </Box>
        )}

        <Box width="100%">
          <TextInputField required {...NAME_FIELD} errors={errors} register={register} />
        </Box>

        {trigger && trigger.type === "date_time" && (
          <Box width="350px">
            <DatePickerField required preserveTime control={control} {...TRIGGER_DATE_TIME_FIELD} errors={errors} />
          </Box>
        )}

        {actionType && ["email", "chat"].includes(actionType) && (
          <Heading size="xs" mt="6">
            Content
          </Heading>
        )}

        {actionType && ["task_reminder"].includes(actionType) && (
          <TextInputField {...MESSAGE_FIELD} errors={errors} register={register} />
        )}

        <>
          {actionType === "email" && <TextInputField {...SUBJECT_FIELD} errors={errors} register={register} />}
          {actionType === "email" && (
            <ActionRichTextAreaField
              actionType={actionType}
              targetType={targetType}
              {...BODY_FIELD}
              errors={errors}
              control={control}
            />
          )}
          {actionType === "chat" && (
            <ActionRichTextAreaField
              actionType={actionType}
              targetType={targetType}
              {...BODY_FIELD}
              label="Message"
              placeholder="Message"
              errors={errors}
              control={control}
            />
          )}
        </>
      </Stack>
      <HStack mt={12} justify="end">
        <ProceedCancelButtons
          handleSubmit={handleSubmit(onSubmit)}
          onClose={() => onClose?.()}
          proceedText={proceedText}
          proceedDisabled={!isDirty}
          isLoading={isLoading}
        />
      </HStack>
    </>
  );
}
