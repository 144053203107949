import {
  Card, CardBody, Flex, HStack, VStack, Wrap, WrapItem,
} from "@chakra-ui/react";
import NewHireJourneyResources from "components/NewHireJourney/NewHireJourneyResources";
import NewHireJourneyChecklist from "components/NewHireJourney/NewHireJourneyChecklist";
import { ExtendedNewHireJourney } from "models";

interface OverviewTabProps {
  newHireJourney: ExtendedNewHireJourney;
}

export default function OverviewTab({ newHireJourney }: OverviewTabProps) {
  return (
    <VStack align="flex-start">
      <HStack>
        {/* ADD / CHANGE BUDDY */}
        {/* ADD / CHANGE ONBOARDING PLAN */}
        {/* START / STOP AUTOMATIONS */}
      </HStack>
      <Wrap width="100%" spacingX="4">
        <WrapItem>
          <Flex mt="4" gap="2" direction="column" height="100%">
            <Card py="2">
              <CardBody>
                <NewHireJourneyChecklist newHireJourney={newHireJourney} />
              </CardBody>
            </Card>
            <NewHireJourneyResources newHireJourney={newHireJourney} />
          </Flex>
        </WrapItem>
      </Wrap>
    </VStack>
  );
}
