import { MenuItem } from "@chakra-ui/react";
import ButtonWithConfirm from "components/Button/ButtonWithConfirm";
import { VThreeDotMenu } from "components/Menu/ThreeDotMenu";
import { MouseEventHandler, PropsWithChildren } from "react";
import { CombinedAction } from "models/automation/combinedAction";
import { isTask } from "features/Task/helpers";
import { useRemoveAssignedAction, useForceSendAssignedAction, useSkipAssignedAction } from "../hooks";

interface DotMenuProps extends PropsWithChildren {
  combinedAction: CombinedAction;
  handleEdit: MouseEventHandler<HTMLButtonElement>;
}

const CONFIRM_REMOVE = {
  header: "CONFIRM removing this Assigned Action",
  body: "Are you sure you want to remove this Assigned Action?",
  confirmButtonText: "Yes, remove",
};

const CONFIRM_SEND_NOW = {
  header: "CONFIRM sending this Assigned Action now",
  body: "This cannot be undone! Be sure you want to send this action now.",
  confirmButtonText: "Confirm send now",
};

export default function DotMenu({ combinedAction, handleEdit }: DotMenuProps) {
  const assignedAction = isTask(combinedAction) ? combinedAction.taskNotifier : combinedAction;
  const { mutate: handleRemove } = useRemoveAssignedAction({ action: assignedAction });
  const { mutate: handleForceSend } = useForceSendAssignedAction({ action: assignedAction });
  const { mutate: handleSkip } = useSkipAssignedAction({ action: assignedAction });

  if (!assignedAction || assignedAction?.status === "processed") {
    return null;
  }

  return (
    <VThreeDotMenu size="2xs" inPortal>
      <ButtonWithConfirm
        {...CONFIRM_SEND_NOW}
        intent="confirmation"
        handleConfirm={() => handleForceSend()}
      >
        <MenuItem>Send Now</MenuItem>
      </ButtonWithConfirm>
      <MenuItem onClick={handleEdit}>Edit</MenuItem>
      <MenuItem onClick={() => handleSkip()}>
        {assignedAction.status === "skipped" ? "Unskip" : "Skip"}
      </MenuItem>
      <ButtonWithConfirm {...CONFIRM_REMOVE} intent="warning" handleConfirm={() => handleRemove()}>
        <MenuItem>Remove</MenuItem>
      </ButtonWithConfirm>
    </VThreeDotMenu>
  );
}
