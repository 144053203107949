import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalProps,
} from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import React, { useCallback, useMemo } from "react";

interface ModalFormWrapperProps extends Partial<ModalProps> {
  // eid represents the id of the form being edited
  eid?: string;
  modalHeader: string | JSX.Element;
  button: JSX.Element;
}

export default function ModalFormWrapper({
  eid = undefined, modalHeader, button, children, ...rest
}: ModalFormWrapperProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const defaultIsOpen = useMemo(() => searchParams.has("eid") && searchParams.get("eid") === eid, [eid, searchParams]);
  const wrappedOnClose = useCallback(() => {
    if (eid && searchParams.has("eid") && searchParams.get("eid") === eid) searchParams.delete("eid");
    setSearchParams(searchParams);
  }, [eid, searchParams, setSearchParams]);
  const wrappedOnOpen = useCallback(() => {
    if (eid && (!searchParams.has("eid") || (searchParams.has("eid") && searchParams.get("eid") !== eid))) setSearchParams({ eid });
  }, [eid, searchParams, setSearchParams]);

  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen, onClose: wrappedOnClose, onOpen: wrappedOnOpen });

  const initialRef = React.useRef(null);

  return (
    <>
      {React.cloneElement(button as JSX.Element, { onClick: onOpen })}

      <Modal initialFocusRef={initialRef} closeOnOverlayClick={false} {...rest} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={24}>{modalHeader}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>{React.cloneElement(children as JSX.Element, { onClose })}</ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
