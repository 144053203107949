import { User } from "models/user";
import { Company } from "models/company";
import { NewHireJourney } from "models/newHire";
import ROLE from "models/role";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { pick } from "lodash";

type CaminoStore = {
  currentUser: Partial<User> | null;
  isImpersonating: boolean;
  currentRole: ROLE | null;
  currentCompany: Partial<Company> | null;
  currentNewHireJourney: NewHireJourney | null;
  updateCurrentUser: (user: Partial<User> | null) => void;
  setValue: (value: Partial<CaminoStore>) => void;
};

type CaminoStoreValues = Pick<
CaminoStore,
"currentUser" | "currentRole" | "currentCompany" | "currentNewHireJourney" | "isImpersonating"
>;

const currentRole = (state: CaminoStore, user: Partial<User> | null = null) => {
  if (!user) return null;
  return state?.currentRole || user.permission?.[0] || state?.currentUser?.permission?.[0] || null;
};

const useCaminoStore = create(
  persist(
    (set, get: () => CaminoStore) => ({
      currentUser: null,
      isImpersonating: false,
      currentRole: null,
      currentCompany: null,
      currentNewHireJourney: null,
      updateCurrentUser: (user: Partial<User> | null) => {
        if (!user) {
          set({ currentUser: null, currentRole: null });
          return;
        }
        const state = get();

        const tempUser = { ...state.currentUser, ...user };
        if (Object.keys(tempUser).length === 0) {
          set({ currentUser: null, currentRole: null });
        } else {
          set({
            currentUser: pick(tempUser, [
              "id",
              "firstName",
              "lastName",
              "fullName",
              "title",
              "permission",
              "companyId",
              "workEmail",
              "personalEmail",
              "avatarUrl",
              "pronouns",
              "location",
              "timezone",
              "linkedinUrl",
              "sendMessageAuthorized",
            ]),
            currentRole: currentRole(state, tempUser),
          });
        }
      },
      setValue: (value: Partial<CaminoStoreValues>) => {
        const previousState = get();
        const { currentUser } = value;
        const company = value.currentCompany || currentUser?.company;
        const newHireJourney = value.currentUser?.onboardingJourney;
        if (currentUser) {
          previousState.updateCurrentUser(currentUser);
        }
        if (value.currentRole) {
          set({ currentRole: value.currentRole });
        } else if (
          (currentUser && !value.currentRole)
          || (previousState.currentRole && !currentUser?.permission?.includes(previousState.currentRole))
        ) {
          set({ currentRole: currentRole(get(), currentUser) });
        }
        if (company) {
          set({ currentCompany: pick(company, ["id", "name", "slug", "domain", "logoUrl", "websiteUrl"]) });
        }
        if (newHireJourney) {
          set({ currentNewHireJourney: newHireJourney });
        }
        if (value.isImpersonating !== undefined) {
          set({ isImpersonating: value.isImpersonating });
        }
      },
    }),
    { name: "camino-storage" },
  ),
);

export default useCaminoStore;
