import { useToast } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { combinedActionKeys } from "features/CombinedAction/hooks";
import { newHireJourneyKeys } from "features/NewHireJourney/hooks";
import { generatePath } from "react-router-dom";
import apiClient from "services/ApiClient";
import { CombinedAction, CombinedActionId } from "models/automation/combinedAction";
import { apiIssuesPathByActionId } from "helpers/url";

const issueKeys = {
  all: ["issues"] as const,
  lists: () => [...issueKeys.all, "list"] as const,
  list: (filters: string) => [...issueKeys.lists(), { filters }] as const,
  details: () => [...issueKeys.all, "detail"] as const,
  detail: (id: string) => [...issueKeys.details(), id] as const,
  byActionId: (actionId: string) => [...issueKeys.all, "action", actionId] as const,
};

// export const useIssueDetailQuery = (id: string, options = {}) => useQuery<Issue>({
//   queryKey: issueKeys.detail(id),
//   queryFn: async () => apiClient.get(apiIssuePathById(id)),
//   staleTime: 1000 * 60 * 3, // 3 minutes
//   ...options,
// });

export const useIssueListQuery = () => useQuery({
  queryKey: issueKeys.lists(),
  queryFn: async () => apiClient.get(generatePath(API_ROUTES.issues.base)),
  staleTime: 1000 * 60 * 3, // 3 minutes
});

export const useIssuesByActionIdQuery = (actionId: string, options = {}) => useQuery({
  queryKey: issueKeys.byActionId(actionId),
  queryFn: async () => apiClient.get(apiIssuesPathByActionId(actionId)),
  staleTime: 1000 * 60 * 3, // 3 minutes
  ...options,
});
