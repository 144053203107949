/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
import {
  Alert, AlertIcon, Card, CardBody, Stack,
} from "@chakra-ui/react";
import { generatePath, useParams } from "react-router-dom";
import AppPage from "layouts/AppPage";
import { useQuery } from "@tanstack/react-query";
import { NetworkingError, isNetworkingError } from "services/ApiClient/networkingError";
import apiClient from "services/ApiClient";
import { API_ROUTES, SHARED_COMPANY_ROUTES } from "definitions/constants/routeConstants";
import { useEffect, useState } from "react";
import UnfoundResource from "components/EmptyComponents/UnfoundResource";
import AssignedPathInfoCard from "components/Path/AssignedPathInfoCard";
import UnknownError from "components/EmptyComponents/UnknownError";
import { QUERY_KEYS } from "definitions/constants";
import { AssignedAction, AssignedPath } from "models/automation/scheduledWorkflow";
import { getPathName } from "helpers/path";
import { useNavbar } from "hooks/useNavbar";
import { RandomEmptyActionState } from "components/EmptyComponents/RandomEmptyState";
import NewHireJourneyTitleBar from "pages/Shared/NewHires/NewHiresTitleBar";
import AssignedActionCardTable from "features/AssignedAction/AssignedActionCardTable";

export default function AssignedPathPage() {
  const { id } = useParams<{ id: string }>();
  const [isNotFound, setIsNotFound] = useState(false);
  const { setBreadcrumbs, setPageTitle } = useNavbar();
  const [actionsWithOnboardingJourney, setActionsWithOnboardingJourney] = useState<AssignedAction[]>([]);

  const {
    data,
    isLoading,
    error: queryError,
  } = useQuery({
    queryKey: [QUERY_KEYS.assignedPaths, id],
    queryFn: async ({ signal }) => apiClient.get<AssignedPath>(generatePath(API_ROUTES.assignedPaths.show, { id }), signal),
    retry: (failureCount, error) => {
      if (isNetworkingError(error) && error.status === 404) {
        return failureCount < 1;
      }
      return failureCount < 3;
    },
    refetchOnWindowFocus: !isNotFound,
  });

  useEffect(() => {
    if (data && !isLoading) {
      setActionsWithOnboardingJourney(
        data.actions.filter((action) => ["chat", "email", "task_notifier"].includes(action.actionType)).map((action) => ({ ...action, onboardingJourney: data.onboardingJourney })),
      );
    }
  }, [data, setActionsWithOnboardingJourney, isLoading]);

  useEffect(() => {
    if (data && !isLoading) {
      setBreadcrumbs([
        {
          name: "New Hires",
          toUrl: generatePath(SHARED_COMPANY_ROUTES.newHireJourneys.index),
          isCurrentPage: false,
        },
        {
          name: `${data?.onboardingJourney?.user?.firstName}`,
          toUrl: generatePath(SHARED_COMPANY_ROUTES.newHireJourneys.show, {
            id: data?.onboardingJourneyId || "id",
          }),
          isCurrentPage: false,
        },
        {
          name: `${data?.onboardingJourney?.user?.firstName}'s ${getPathName(data?.workflow)}`,
          isCurrentPage: true,
        },
      ]);
      setPageTitle(`${data?.onboardingJourney?.user?.firstName}'s ${getPathName(data?.workflow)}`);
    }
  }, [data, isLoading, setBreadcrumbs, setPageTitle]);

  useEffect(() => {
    // to stop refetches
    if ((queryError as NetworkingError)?.status === 404) {
      setIsNotFound(true);
    }
  }, [queryError]);

  return (
    <AppPage>
      {queryError ? (
        (queryError as NetworkingError)?.status === 404 ? (
          <UnfoundResource resourceId={id} resourceName="Assigned Path" />
        ) : (
          <UnknownError resourceId={id} resourceName="Assigned Path" />
        )
      ) : (
        data && (
          <>
            <NewHireJourneyTitleBar newHireJourney={data.onboardingJourney} mb="8" />
            <Stack>
              <AssignedPathInfoCard isLoading={isLoading} data={data} />
              <Card mt="8" p="4">
                <CardBody>
                  <Alert status="info">
                    <AlertIcon />
                    This is an ASSIGNED PATH, assigned to a new hire - If you want to edit template path, access it by
                    clicking into the "Original Path" link above
                  </Alert>
                  <AssignedActionCardTable
                    data={actionsWithOnboardingJourney}
                    emptyStateComponent={<RandomEmptyActionState />}
                    isLoading={isLoading}
                  />
                </CardBody>
              </Card>
            </Stack>
          </>
        )
      )}
    </AppPage>
  );
}
