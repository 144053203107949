import {
  Button, Heading, SkeletonText, StackProps, VStack,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { NewHireJourney } from "models/newHire";
import { useEffect, useMemo, useState } from "react";
import { validDate } from "utils/dateFormatter";
import UserSelectField from "components/FormElements/Fields/SelectFields/UserSelectField";
import TextInputField from "components/FormElements/Fields/InputFields/TextInputField";
import DatePickerField from "components/FormElements/Fields/DatePickerField";

const START_DATE_FIELD = {
  type: "date",
  label: "Start Date",
  name: "startDate",
};

const BUDDY_ID_FIELD = {
  type: "text",
  label: "Buddy",
  name: "buddyId",
};

const MANAGER_ID_FIELD = {
  type: "text",
  label: "Manager",
  name: "managerId",
};

const PEOPLE_TEAM_CONTACT_ID_FIELD = {
  type: "text",
  label: "People Team Contact",
  name: "peopleTeamContactId",
};

const ONBOARDING_PLAN_URL_FIELD = {
  label: "Onboarding Plan Url",
  name: "onboardingPlanUrl",
  helperText: "Url should include the prefix (http:// or https://)",
  type: "url",
  defaultValue: "https://",
};

const resourceSchema = yup.object({
  startDate: yup.date().nullable().default(null).label(START_DATE_FIELD.label),
  managerId: yup.string().nullable().default(null).label(MANAGER_ID_FIELD.label),
  peopleTeamContactId: yup.string().nullable().default(null).label(PEOPLE_TEAM_CONTACT_ID_FIELD.label),
  buddyId: yup.string().nullable().default(null).label(BUDDY_ID_FIELD.label),
  onboardingPlanUrl: yup.string().url().nullable().default(null)
    .label(ONBOARDING_PLAN_URL_FIELD.label),
});

const values = (data: NewHireJourney | undefined) => ({
  startDate: validDate(data?.startDate) || null,
  managerId: data?.manager?.id || null,
  peopleTeamContactId: data?.peopleTeamContact?.id || null,
  buddyId: data?.buddy?.id || null,
  onboardingPlanUrl: data?.onboardingPlanUrl || null,
});

interface NewHireJourneyFormValues {
  startDate: Date | null;
  peopleTeamContactId: string | null;
  buddyId: string | null;
  managerId: string | null;
  onboardingPlanUrl: string | null;
}

interface NewHireJourneyFormProps extends StackProps {
  data: NewHireJourney | undefined;
  isLoading: boolean;
  onFormSubmit: (data: NewHireJourneyFormValues) => void;
}

export default function NewHireJourneyForm({ data, isLoading, onFormSubmit, ...rest }: NewHireJourneyFormProps) {
  const defaultValues = useMemo(() => values(data), [data]);
  const excludedIds = useMemo(() => (data ? [data.user.id] : []), [data]);

  const {
    control,
    register,
    formState: { errors, isDirty, isValid },
    handleSubmit,
    reset,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(resourceSchema),
    defaultValues,
  });

  const [cacheKey, setCacheKey] = useState(excludedIds.join("-"));

  useEffect(() => {
    reset({ ...defaultValues });
  }, [defaultValues, reset]);

  useEffect(() => {
    setCacheKey(excludedIds.join("-"));
  }, [setCacheKey, excludedIds]);

  if (!data) return <SkeletonText mt="4" noOfLines={12} spacing="4" skeletonHeight="4" speed={1.75} />;

  return (
    <VStack spacing="3" alignItems="start" width="100%" {...rest}>
      <Heading my="4" size="xs" color="fg.muted">
        Edit New Hire Info
      </Heading>
      <VStack spacing="3" width="100%">
        <DatePickerField {...START_DATE_FIELD} errors={errors} control={control} />
        <VStack spacing="3" width="100%">
          <TextInputField {...ONBOARDING_PLAN_URL_FIELD} errors={errors} register={register} />
        </VStack>
        <UserSelectField
          cacheKey={cacheKey}
          updateCacheKey={setCacheKey}
          excludedIds={excludedIds}
          isClearable
          control={control}
          {...MANAGER_ID_FIELD}
          errors={errors}
        />
        <UserSelectField
          cacheKey={cacheKey}
          updateCacheKey={setCacheKey}
          excludedIds={excludedIds}
          isClearable
          control={control}
          {...PEOPLE_TEAM_CONTACT_ID_FIELD}
          errors={errors}
        />
        <UserSelectField
          cacheKey={cacheKey}
          updateCacheKey={setCacheKey}
          excludedIds={excludedIds}
          isClearable
          control={control}
          {...BUDDY_ID_FIELD}
          errors={errors}
        />
      </VStack>
      <Button
        isLoading={isLoading}
        isDisabled={!(isDirty && isValid)}
        alignSelf="end"
        width="30%"
        minW={40}
        onClick={handleSubmit(onFormSubmit)}
      >
        Update
      </Button>
    </VStack>
  );
}
