import LocationSelect, { LocationDetails, } from "components/Form/Select/LocationSelect";
import { UserLocationFieldProps } from "components/Form/typings";
import { useCallback, useMemo } from "react";
import { useTheme } from "@chakra-ui/react";
import { useWatch } from "react-hook-form";
import FormField from "../FormField";
import { compact } from "lodash";

export default function LocationSelectField({
  name,
  label,
  control,
  helperText,
  errors,
  required,
  disabled,
  setValue,
  associatedFields,
  hiddenFields,
  variant,
  formFieldProps = {},
  placeholder,
  ...rest
}: UserLocationFieldProps) {
  const theme = useTheme();
  // Accessing default variant from the theme
  const formVariant = variant || theme.components.Form.defaultProps.variant;
  const [formGoogleLocationId, rawLocation] = useWatch({
    control,
    name: ["googleLocationId", "rawLocation"],
  });

  const initialValue = useMemo(() => formGoogleLocationId && { label: rawLocation, placeId: formGoogleLocationId }, [formGoogleLocationId])

  const handleLocationUpdate = useCallback(
    (location: LocationDetails) => {
      if (location) {
        setValue(name, compact([location.addressComponents.city, location.addressComponents.state]).join(", "), { shouldDirty: true });

        [...(hiddenFields || []), ...(associatedFields || [])].forEach((field) => {
          switch (field.name) {
            case "country":
              setValue(field.name, location.addressComponents.country, { shouldDirty: true });
              break;
            case "googleLocationId":
              setValue(field.name, location.id, { shouldDirty: true });
              break;
            case "rawLocation":
              setValue(field.name, location.label, { shouldDirty: true });
              break;
            case "timezone":
              setValue(field.name, location.timezone, { shouldDirty: true })
              break;
            default:
              break;
          }
        });
      }
    },
    [setValue, name, hiddenFields],
  );
  return (
    <FormField
      name="location"
      label={label}
      errors={errors}
      helperText={helperText}
      required={required}
      disabled={disabled}
      variant={formVariant}
      {...formFieldProps}
    >
      <LocationSelect
        key={`location-id-${formGoogleLocationId}`}
        onChange={handleLocationUpdate}
        variant={formVariant}
        initialValue={initialValue}
        {...rest}
      />
    </FormField>
  );
}
