import { Box, HStack, Text } from "@chakra-ui/react";
import { FieldPathByValue, UseFormReturn } from "react-hook-form";
import SingleSelectField from "components/FormElements/Fields/SelectFields/SingleSelectField";
import { Maybe } from "yup";
import { schedulingFields } from "../fields";
import { SharedSchema, TriggerSchema } from "../schema";
import generateSchedulingSummary from "../helpers";
import TimeOfDay from "./TimeOfDay";

export default function Scheduling<
  Schema extends SharedSchema,
  TFieldName extends FieldPathByValue<Schema, Maybe<TriggerSchema>> = FieldPathByValue<Schema, Maybe<TriggerSchema>>,
>({ fieldName, hideTimeOfDay, form, required = true }: { required?: boolean; fieldName: TFieldName; hideTimeOfDay?: boolean; form: UseFormReturn<Schema> }) {
  const {
    control,
    formState: { errors },
    watch,
  } = form;
  const fields = schedulingFields(fieldName);
  const triggerWatch = watch(fieldName);
  const trigger = triggerWatch as TriggerSchema; // FIXME: Not able to get watch to appropriately type the resulting value, so casting for the moment. Should figure this out

  let schedulingSummary = "";
  if (trigger && trigger.type === "timing" && trigger.data) schedulingSummary = generateSchedulingSummary({ field: fieldName, trigger });
  const offsetDirectionOptions = fields.offsetDirection.options.filter(
    (option) => option.value !== "before" || trigger?.data?.referenceEvent !== "immediate",
  );

  return (
    <>
      {schedulingSummary.length > 0 && <Text fontSize="s" fontStyle="italic">{`Summary: ${schedulingSummary}`}</Text>}
      <HStack width="100%">
        <Box display="inline-block" width="50%">
          <SingleSelectField
            isClearable={false}
            required={required}
            {...fields.offsetDirection}
            options={offsetDirectionOptions}
            errors={errors}
            control={control}
          />
        </Box>
        <Box width="50%">
          <SingleSelectField
            isClearable={false}
            required={required}
            {...fields.referenceEvent}
            errors={errors}
            control={control}
          />
        </Box>
      </HStack>

      {trigger?.data?.offsetDirection && trigger.data.offsetDirection !== "exact" && (
        <HStack width="100%">
          <Box display="inline-block" width="20%">
            <SingleSelectField
              menuPlacement="top"
              isClearable={false}
              required={required}
              {...fields.offsetValue}
              errors={errors}
              control={control}
            />
          </Box>
          <Box display="inline-block" width="30%">
            <SingleSelectField
              menuPlacement="top"
              isClearable={false}
              required={required}
              {...fields.offsetUnit}
              errors={errors}
              control={control}
            />
          </Box>
          {trigger.data.offsetUnit === "specific_day" && (
            <Box width="25%">
              <SingleSelectField
                menuPlacement="top"
                isClearable={false}
                required={required}
                {...fields.dayOfWeek}
                errors={errors}
                control={control}
              />
            </Box>
          )}
        </HStack>
      )}
      {!hideTimeOfDay && <TimeOfDay<Schema> form={form} fieldName={fieldName} />}
    </>
  );
}

Scheduling.defaultProps = {
  hideTimeOfDay: false,
};
