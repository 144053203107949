import { useToast } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { Action } from "models/automation";
import { generatePath, useNavigate } from "react-router-dom";
import { API_ROUTES, PEOPLE_TEAM_ROUTES } from "definitions/constants/routeConstants";
import apiClient from "services/ApiClient";
import { QUERY_KEYS } from "definitions/constants";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import Alert from "components/Alert/Alert";
import { useCallback, useState } from "react";
import { AlertState } from "models/alert";
import useCaminoStore from "hooks/useCaminoStore";
import { AssignedActionCard } from "./index";

const ALERT_SUCCESS_TITLE = "Message Sent!";
const ALERT_SUCCESS_BODY = (destination: string) => `A test was sent to ${destination}`;
const ALERT_FAILURE_TITLE = "Error";
const ALERT_FAILURE_BODY = (destination: string) => `There was an error trying to send to ${destination}`;
const ALERT_BUTTON_TITLE = "Close";

interface ScheduledActionCardProps {
  scheduledAction: AssignedAction;
  isLoading: boolean;
}

export default function ScheduledActionCard({ scheduledAction, isLoading }: ScheduledActionCardProps) {
  const toast = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [alertState, setAlertState] = useState<AlertState>({ isOpen: false, isFailure: false });
  const [user] = useCaminoStore((state) => [state.currentUser]);

  const onTestSend = useCallback(async () => {
    try {
      const resp = await apiClient.post<{ success: boolean }>(
        generatePath(API_ROUTES.assignedActions.testSend, { id: scheduledAction?.id }),
        {},
      );
      console.log(resp);
      setAlertState({ ...{ isOpen: true, isFailure: false } });
    } catch (error) {
      setAlertState({ ...{ isOpen: true, isFailure: true } });
    }
  }, [scheduledAction?.id]);

  const onClose = useCallback(() => {
    setAlertState({ isOpen: false, isFailure: false });
  }, [setAlertState]);

  const onDelete = useCallback(async () => {
    try {
      const res = await apiClient.delete<Action>(
        generatePath(API_ROUTES.assignedActions.show, {
          id: scheduledAction?.id,
        }),
      );
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.assignedActions, scheduledAction?.id] });
      console.log(res);
      toast({
        title: "Action Deleted.",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
      navigate(generatePath(PEOPLE_TEAM_ROUTES.assignedPaths.show, { id: scheduledAction?.scheduledWorkflow?.id }));
    } catch (error) {
      console.error(error);
      toast({
        title: "Error",
        description: "There was an error, please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    }
  }, [navigate, queryClient, scheduledAction?.id, scheduledAction?.scheduledWorkflow?.id, toast]);

  return (
    <>
      <AssignedActionCard action={scheduledAction} onTestSend={onTestSend} onDelete={onDelete} isLoading={isLoading} />
      <Alert
        title={alertState.isFailure ? ALERT_FAILURE_TITLE : ALERT_SUCCESS_TITLE}
        body={
          alertState.isFailure
            ? ALERT_FAILURE_BODY(user?.fullName as string)
            : ALERT_SUCCESS_BODY(user?.fullName as string)
        }
        buttonTitle={ALERT_BUTTON_TITLE}
        isOpen={alertState.isOpen}
        onClose={onClose}
      />
    </>
  );
}
