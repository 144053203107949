import { generatePath, useParams } from "react-router-dom";
import AppPage from "layouts/AppPage";
import { useQuery } from "@tanstack/react-query";
import apiClient from "services/ApiClient";
import { API_ROUTES, SHARED_COMPANY_ROUTES, SHARED_ROUTES } from "definitions/constants/routeConstants";
import { QUERY_KEYS } from "definitions/constants";
import { useNavbar } from "hooks/useNavbar";
import { useEffect } from "react";
import { Task } from "models";
import AssignedTaskCard from "features/Task/AssignedTaskCard";

export default function TaskPage() {
  const { id, newHireJourneyId } = useParams<{ id: string; newHireJourneyId: string }>();

  const { data, isLoading } = useQuery<Task>({
    queryKey: [QUERY_KEYS.task, id],
    queryFn: async () => apiClient.get(generatePath(API_ROUTES.tasks.show, { id })),
  });

  const { setBreadcrumbs, setPageTitle } = useNavbar();

  useEffect(() => {
    setPageTitle("My Tasks");
    if (data && !isLoading) {
      if (newHireJourneyId) {
        setBreadcrumbs([
          {
            name: "New Hire",
            toUrl: generatePath(SHARED_COMPANY_ROUTES.newHireJourneys.show, { id: newHireJourneyId }),
            isCurrentPage: false,
          },
          {
            name: "Tasks",
            toUrl: `${generatePath(SHARED_COMPANY_ROUTES.newHireJourneys.show, { id: newHireJourneyId })}?t=2`,
            isCurrentPage: false,
          },
          {
            name: data?.title,
            isCurrentPage: true,
          },
        ]);
      } else {
        setBreadcrumbs([
          {
            name: "My Tasks",
            toUrl: generatePath(SHARED_ROUTES.tasks.index),
            isCurrentPage: false,
          },
          {
            name: data?.title,
            isCurrentPage: true,
          },
        ]);
      }
    }
  }, [newHireJourneyId, data, isLoading, setBreadcrumbs, setPageTitle]);

  return (
    <AppPage>
      <AssignedTaskCard task={data} isLoading={isLoading} />
    </AppPage>
  );
}
