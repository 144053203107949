import { FlexProps } from "@chakra-ui/react";
import { dateToLongString, timeAtSpecificDayMonth } from "helpers/time";
import { ExtendedNewHireJourney, Task } from "models";
import { NewHireJourney } from "models/newHire";
import { BsCalendarEventFill } from "react-icons/bs";
import { actionTargetText, actionableTypeToIcon, assignedActionTargetText, taskTypeToIcon } from "helpers/workflowActionableConverters";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { titleize } from "helpers/string";
import { FaUser } from "react-icons/fa6";
import { BUSINESS_ICONS } from "definitions/constants/icons";
import { Action } from "models/automation";
import { TASK_TYPES } from "definitions/constants";
import PathIcon from "assets/Path";
import { TitleCardAttribute, TitleCardSkeleton } from "./TitleCard";

interface TitleCardAttributeProps extends FlexProps {
  newHireJourney: ExtendedNewHireJourney | NewHireJourney | undefined;
}

export function TitleCardAttributeStartDate({ newHireJourney, ...restProps }: TitleCardAttributeProps) {
  return (
    <TitleCardAttribute
      icon={BsCalendarEventFill}
      label="Start Date"
      value={dateToLongString(newHireJourney?.startDate)}
      {...restProps}
    />
  );
}

export function TitleCardAttributeNewHire({ newHireJourney, ...restProps }: TitleCardAttributeProps) {
  return (
    <TitleCardAttribute
      label="New Hire"
      value={newHireJourney?.user?.fullName as string}
      icon={PathIcon}
      {...restProps}
    />
  );
}

interface TitleCardAttributeActionTypeProps extends FlexProps {
  action: AssignedAction | Action;
}

interface TitleCardAttributeAssignedActionTypeProps extends FlexProps {
  action: AssignedAction;
}

export function TitleCardAttributeActionType({ action, ...restProps }: TitleCardAttributeActionTypeProps) {
  if (!action) return <TitleCardSkeleton />;
  const isActionable = (action as Action).actionable !== undefined;
  const icon = isActionable
    ? actionableTypeToIcon((action as Action).actionable)
    : actionableTypeToIcon({
      actionType: (action as AssignedAction).actionType,
      taskType: (action as AssignedAction).taskType,
    });

  return (
    <TitleCardAttribute
      icon={icon}
      label="Action Type"
      value={titleize(isActionable ? (action as Action).actionable.actionType : (action as AssignedAction).actionType)}
      {...restProps}
    />
  );
}

export function TitleCardAttributeAssignedActionScheduledAt({
  action,
  ...restProps
}: TitleCardAttributeAssignedActionTypeProps) {
  if (!action) return <TitleCardSkeleton />;
  return (
    <TitleCardAttribute
      icon={BUSINESS_ICONS.TRIGGER_AT}
      label="Scheduled At"
      value={timeAtSpecificDayMonth(action?.trigger?.data?.datetime) || ""}
      {...restProps}
    />
  );
}

export function TitleCardAttributeRecipient({ action, ...restProps }: TitleCardAttributeActionTypeProps) {
  if (!action) return <TitleCardSkeleton />;
  const isActionable = (action as Action).actionable !== undefined;
  const value = isActionable ? actionTargetText(action as Action) : assignedActionTargetText(action as AssignedAction);

  return <TitleCardAttribute label="Recipient" value={value as string} icon={FaUser} {...restProps} />;
}

interface TitleCardAttributeTaskTypeProps extends FlexProps {
  task: Task;
}

export function TitleCardAttributeAssignedTo({ task, ...restProps }: TitleCardAttributeTaskTypeProps) {
  if (!task) return <TitleCardSkeleton />;
  return <TitleCardAttribute label="Assigned" value={task?.assignee?.fullName as string} icon={FaUser} {...restProps} />;
}

export function TitleCardAttributeTaskType({ task, ...restProps }: TitleCardAttributeTaskTypeProps) {
  if (!task) return <TitleCardSkeleton />;
  const title = task.taskType === TASK_TYPES.CUSTOM ? "Basic Task" : titleize(task.taskType);
  return <TitleCardAttribute label="Type" value={title as string} icon={taskTypeToIcon(task.taskType)} {...restProps} />;
}
