import { Flex, Link, Tag, TagLabel } from "@chakra-ui/react";
import { Link as RouterLink, generatePath } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { API_ROUTES, SHARED_ROUTES } from "definitions/constants/routeConstants";
import { taskDateDisplay } from "helpers/time";
import apiClient from "services/ApiClient";
import { useCallback, useEffect, useMemo } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "definitions/constants";
import { FilterMeta, ResponseModel } from "services/ApiClient/responseModel";
import { Task } from "models";
import { BaseTask } from "models/task";
import ClickableTaskStatus from "layouts/Task/ClickableTaskStatus";
import { RandomEmptyTaskState } from "components/EmptyComponents/RandomEmptyState";
import { MinimalTaskStatus, TaskStatusWithTime } from "features/Task/TaskStatus";
import { NewHireJourney } from "models/newHire";
import useQueryString from "hooks/useQueryString";
import { sortTaskRowsByNotifier } from "features/Task/helpers";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { assignedActionStatusDisplay } from "helpers/workflowActionableConverters";
import { assignedActionPath } from "helpers/url";
import { TaskStatusType } from "components/Selectable/SelectableTasksByStatus";
import ControlledDataTable, { ControlledDataTableProps } from "./ControlledDataTable";

interface NotificationReceivedProps {
  assignedAction: AssignedAction;
}

export function NotificationReceived({ assignedAction }: NotificationReceivedProps) {
  if (!assignedAction) {
    return null;
  }
  const { status, datetime } = assignedActionStatusDisplay(assignedAction);
  const tagDisplay = `${status} ${datetime}`;
  return (
    <RouterLink to={assignedActionPath(assignedAction)}>
      <Tag variant="subtle" colorScheme="gray">
        <TagLabel>{tagDisplay}</TagLabel>
      </Tag>
    </RouterLink>
  );
}

const columnHelper = createColumnHelper<Task>();

const makeColumns = (displayNewHireName: boolean, onSuccess: (response: BaseTask) => void) => [
  columnHelper.accessor((row) => row, {
    id: "completed?",
    header: "",
    cell: (row) => <ClickableTaskStatus task={row.getValue()} onSuccess={onSuccess} />,
    maxSize: 8,
    size: 8,
    meta: {
      sx: { minWidth: "64px", paddingLeft: "32px" },
    },
  }),
  columnHelper.accessor((row) => row, {
    id: "title",
    header: "Title",
    cell: (row) => (
      <Flex ml="4">
        <Link
          {...(row.getValue().completedAt || row.getValue().status === "skipped"
            ? { textDecoration: "line-through" }
            : {})}
          as={RouterLink}
          to={{
            pathname: generatePath(SHARED_ROUTES.tasks.show, {
              id: row.getValue().id,
            }),
          }}
        >
          {row.getValue().title}
        </Link>
      </Flex>
    ),
    minSize: 140,
    size: 300,
    sortingFn: "notifierSortingFn",
  }),
  columnHelper.accessor((row) => row, {
    id: "notificationDate",
    header: "",
    cell: (row) => <TaskStatusWithTime mt="1" size="sm" task={row.getValue()} />,
    disableSorting: true,
    minSize: 120,
    size: 240,
  }),
  displayNewHireName
    ? columnHelper.accessor((row) => row, {
      id: "newHireJourneyId",
      header: "New Hire",
      cell: (row) => (row.getValue()?.newHireJourney?.user?.id === row.getValue()?.assigneeId
        ? "My Onboarding"
        : row.getValue()?.newHireJourney?.user?.fullName),
      minSize: 20,
    })
    : columnHelper.accessor((row) => row, {
      id: "assigneeId",
      header: "Assignee",
      cell: (row) => row.getValue()?.assignee?.fullName || "TBD",
      minSize: 20,
    }),
  columnHelper.accessor((row) => row, {
    id: "dueDate",
    header: "Due On",
    cell: (row) => (row.getValue().dueDate ? taskDateDisplay(row.getValue().dueDate as Date) : "No Due Date"),
    minSize: 20,
  }),
];

interface TasksTableProps {
  searchText?: string;
  statusFilter?: TaskStatusType;
  displayNewHireName?: boolean;
  newHireJourney?: NewHireJourney;
  newHireJourneyId?: string;
  onDataSuccess?: (response: BaseTask[]) => void;
  filterByUserId?: string;
  includeCommunications?: boolean;
}

export default function TasksTable({
  statusFilter,
  newHireJourney,
  newHireJourneyId,
  filterByUserId,
  searchText = "",
  displayNewHireName = false,
  onDataSuccess = () => {},
  includeCommunications = false,
}: TasksTableProps) {
  const queryClient = useQueryClient();

  const onSuccess = useCallback(
    async (resTask: BaseTask) => {
      queryClient.setQueriesData({
        queryKey: [QUERY_KEYS.tasks, "me"],
      }, (previous: any) => ({
        ...(previous as ResponseModel<BaseTask[]>),
        data: (previous as ResponseModel<BaseTask[]>).data.map((task) => (task.id === resTask.id ? resTask : task)),
      }));
    },
    [queryClient],
  );

  const columns = makeColumns(displayNewHireName as boolean, (res) => onSuccess(res));

  const queries = useMemo(() => {
    const innerQueries: Record<string, string | string[]> = {};
    if (includeCommunications) {
      innerQueries.view = "extended";
    }
    if (searchText && searchText.length > 2) {
      innerQueries.q = searchText;
    }
    if (statusFilter) {
      switch (statusFilter) {
        case "open":
          innerQueries.statuses = ["open"];
          break;
        case "all":
          innerQueries.statuses = ["open", "upcoming", "completed", "skipped"];
          break;
        case "upcoming":
          innerQueries.statuses = ["upcoming"];
          break;
        case "closed":
          innerQueries.statuses = ["completed", "skipped"];
          break;
        default:
          console.error(`Unrecognized statusFilter ${statusFilter} for tasks table`);
      }
    }
    return innerQueries;
  }, [includeCommunications, searchText, statusFilter]);

  const filters: FilterMeta[] = [];

  if (newHireJourney || newHireJourneyId) {
    filters.push({
      fieldName: "onboarding_journey_id",
      value: (newHireJourney?.id as string) || (newHireJourneyId as string),
      operator: "=",
    });
  }

  if (filterByUserId) {
    filters.push({
      fieldName: "user_id",
      value: filterByUserId as string,
      operator: "=",
    });
  }

  const { queryString } = useQueryString(
    {
      pagination: { perPage: 100 },
      filters,
    },
    queries,
  );

  const fetchData = (query, signal) => apiClient
    .performRequest<ResponseModel<Task[]>>(`${API_ROUTES.tasks.base}${query}`, signal)
    .then((response) => response.payload);

  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.tasks, queryString],
    queryFn: async ({ signal }) => fetchData(queryString.length ? `?${queryString}` : "", signal),
    placeholderData: (previousData: any) => previousData,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    // pass the data to the onSuccess function
    onDataSuccess?.(data?.data);
  }, [data, onDataSuccess]);

  const props: ControlledDataTableProps<Task> = {
    customSortingObject: {
      notifierSortingFn: sortTaskRowsByNotifier,
    },
    defaultSort: [{ id: "title", desc: false }],
    data: data?.data || [],
    isLoading,
    columns,
    initialPagination: { pageIndex: 0, pageSize: 100 },
    textWrap: true,
    emptyStateComponent: <RandomEmptyTaskState />,
  };

  return <ControlledDataTable tableOptions={{ enableSortingRemoval: false }} {...props} />;
}
