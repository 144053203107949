import {
  Avatar,
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  HStack,
  Skeleton,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { TitleCardAttribute } from "components/Card/TitleCard";
import { InfoFieldProfile } from "components/DataDisplay/InfoField";
import NewHireJourneyEditButtonWithModal from "components/ModalForm/NewHireJourneyEditForm/NewHireJourneyEditButtonWithModal";
import AddNewPathModal from "components/Path/AddNewPathModal";
import { API_ROUTES, SHARED_COMPANY_ROUTES, SHARED_ROUTES } from "definitions/constants/routeConstants";
import { dateToLongString } from "helpers/time";
import { BsCalendarEventFill } from "react-icons/bs";
import { generatePath, Link as RouterLink, useLocation } from "react-router-dom";
import { NewHireJourney } from "models/newHire";
import StartStopAutomationsButton from "components/NewHireJourney/StartStopAutomationsButton";
import { useCallback } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiClient from "services/ApiClient";
import { QUERY_KEYS } from "definitions/constants";
import { ExtendedNewHireJourney } from "models";
import LinkButton from "components/Button/LinkButton";
import { AssignedPath } from "models/automation/scheduledWorkflow";
import { pendingNewHireJourneyPath } from "helpers/url";

interface NewHireTitleBarProps {
  newHireJourney: ExtendedNewHireJourney | NewHireJourney | undefined;
}

export default function NewHireTitleBar({ newHireJourney, ...restProps }: NewHireTitleBarProps) {
  const { pathname } = useLocation();
  const isNewHirePage = pathname === generatePath(SHARED_COMPANY_ROUTES.newHireJourneys.show, { id: newHireJourney?.id || "" });
  return (
    <Skeleton isLoaded={!!newHireJourney}>
      <Card width="100%" {...restProps}>
        <CardBody mx="8">
          <Flex direction="row" gap="12">
            <Flex direction="column">
              <HStack gap="4">
                <Avatar variant="roundedSquare" size="md" bg="brand.500" src={newHireJourney?.user?.avatarUrl} />
                <Stack gap={0}>
                  {isNewHirePage ? (
                    <Heading size="xs">{newHireJourney?.user?.fullName}</Heading>
                  ) : (
                    <Heading
                      as={RouterLink}
                      size="xs"
                      to={generatePath(SHARED_COMPANY_ROUTES.newHireJourneys.show, { id: newHireJourney?.id || "" })}
                      _hover={{ textDecoration: "underline" }}
                    >
                      {newHireJourney?.user?.fullName}
                    </Heading>
                  )}
                  <Text size="sm">{newHireJourney?.user?.title}</Text>
                </Stack>
              </HStack>
            </Flex>
            <TitleCardAttribute
              icon={BsCalendarEventFill}
              label="Start Date"
              value={dateToLongString(newHireJourney?.startDate)}
            />
            <InfoFieldProfile
              avatarInfo={
                newHireJourney?.peopleTeamContact?.id ? { src: newHireJourney?.peopleTeamContact?.avatarUrl } : {}
              }
              fieldInfo={{
                label: "People Team",
                value: newHireJourney?.peopleTeamContact?.fullName || "TBD",
              }}
            />
            <InfoFieldProfile
              avatarInfo={newHireJourney?.manager?.id ? { src: newHireJourney?.manager?.avatarUrl } : {}}
              fieldInfo={{ label: "Manager", value: newHireJourney?.manager?.fullName || "TBD" }}
            />
            <InfoFieldProfile
              avatarInfo={newHireJourney?.buddy?.id ? { src: newHireJourney?.manager?.avatarUrl } : {}}
              fieldInfo={{ label: "Buddy", value: newHireJourney?.buddy?.fullName || "TBD" }}
            />
          </Flex>
          {newHireJourney && isNewHirePage && (
            <Flex direction="row" justify="flex-start" align="center" gap="8" mt="8">
              <Flex gap="4">
                <LinkButton
                  size="sm"
                  variant="outline"
                  to={generatePath(SHARED_ROUTES.showUser, { id: newHireJourney?.user?.id || "" })}
                >
                  View Profile
                </LinkButton>
                {newHireJourney?.isPending ? (
                  <LinkButton to={pendingNewHireJourneyPath(newHireJourney)} variant="alternative" size="sm">
                    Kick Off Onboarding
                  </LinkButton>
                ) : (
                  <>
                    <NewHireJourneyEditButtonWithModal alignSelf="center" newHireJourney={newHireJourney}>
                      <Button aria-label="edit new hire's information" size="sm">
                        Edit Info
                      </Button>
                    </NewHireJourneyEditButtonWithModal>
                    <AddNewPathModal
                      newHireJourneyId={newHireJourney?.id}
                      currentWorkflowIds={(newHireJourney?.assignedPaths || []).map(
                        (assignedPath: AssignedPath) => assignedPath.workflow.id,
                      )}
                    >
                      <Button variant="camino-secondary" rightIcon={<AddIcon />} size="sm">
                        Add Path(s)
                      </Button>
                    </AddNewPathModal>
                    {newHireJourney?.assignedPaths && newHireJourney?.assignedPaths.length > 0 && (
                      <StartStopAutomationsButton size="sm" newHireJourney={newHireJourney} />
                    )}
                  </>
                )}
              </Flex>
            </Flex>
          )}
        </CardBody>
      </Card>
    </Skeleton>
  );
}
