/* eslint-disable import/prefer-default-export */
import {
  Flex, Text, Skeleton,
} from "@chakra-ui/react";
import { isImmediateCombinedAction } from "helpers/assignedAction";
import { ExtendedNewHireJourney } from "models";
import { PropsWithChildren, useMemo } from "react";

interface ActionOverviewProps extends PropsWithChildren {
  newHireJourney: ExtendedNewHireJourney;
}

export function ImmediateActionsSummary({ newHireJourney }: ActionOverviewProps) {
  const immediateActionsLength = useMemo(() => {
    const combinedActions = [
      ...newHireJourney.tasks,
      ...newHireJourney.assignedPaths
        .flatMap((path) => path.actions)
        .filter((action) => action.actionType === "email" || action.actionType === "chat"),
    ];
    return combinedActions.filter((action) => isImmediateCombinedAction(action)).length;
  }, [newHireJourney]);

  if (!newHireJourney) {
    return <Skeleton height="20px" />;
  }

  if (!immediateActionsLength) {
    return (
      <Text fontSize="xl" color="fg.muted">
        This new hire does not have any emails, chats, or tasks that will be immediately sent.
      </Text>
    );
  }

  const TotalText = (
    <Text as="span" fontWeight="bold" color="brand.500">
      {`${immediateActionsLength} actions`}
    </Text>
  );

  return (
    <Flex direction="column" gap="4">
      <Text fontSize="xl" color="fg.muted">
        {`When you kickoff this onboarding, Camino will send `}
        {TotalText}
        {` immediately.`}
      </Text>
    </Flex>
  );
}

export function TotalActionsSummary({ newHireJourney }: ActionOverviewProps) {
  const totalActionsLength = useMemo(
    () => [
      ...newHireJourney.tasks,
      ...newHireJourney.assignedPaths
        .flatMap((path) => path.actions)
        .filter((action) => action.actionType === "email" || action.actionType === "chat"),
    ].length,
    [newHireJourney],
  );

  if (!newHireJourney) {
    return <Skeleton height="20px" />;
  }

  if (!totalActionsLength) {
    return (
      <Text fontSize="xl" color="fg.muted">
        This new hire does not have any emails, chats, or tasks for their onboarding.
      </Text>
    );
  }

  const TotalText = (
    <Text as="span" fontWeight="bold" color="brand.500">
      {`${totalActionsLength} total actions `}
    </Text>
  );

  return (
    <Flex direction="column" gap="4">
      <Text fontSize="xl" color="fg.muted">
        {TotalText}
        will be sent during onboarding.
      </Text>
    </Flex>
  );
}
