export const DEFAULT_INPUT_VARIANT = "outline";
export const DEFAULT_FIELD_GAP = "10px";

export const DISPLAY_INFO_LABEL_PROPS = {
  fontSize: "sm",
};

export const DISPLAY_INFO_VALUE_PROPS = {
  fontSize: "md",
};

export const floatingNoValueBorder = {
  borderColor: "var(--chakra-colors-gray-200)",
  borderWidth: "2px",
  boxShadow: "none",
};

export const importantFloatingNoValueBorder = {
  border: "2px solid var(--chakra-colors-gray-200) !important",
  boxShadow: "none !important",
};

export const selectedInputBorder = {
  borderColor: "var(--chakra-colors-brand-500)",
  borderWidth: "2px",
  boxShadow: "0px 0px 10px 0px #BCECFD",
};

export const importantSelectedInputBorder = {
  border: "2px solid var(--chakra-colors-brand-500) !important",
  boxShadow: "0px 0px 10px 0px #BCECFD !important",
};

export const formInputWithValueBorder = {
  borderColor: "var(--chakra-colors-hasValueBorder-DEFAULT)",
  borderWidth: "2px",
  boxShadow: "none",
};

export const importantFormInputWithValueBorder = {
  border: "2px solid var(--chakra-colors-hasValueBorder-DEFAULT) !important",
  boxShadow: "none !important",
};
