import { generatePath, useNavigate, useParams } from "react-router-dom";
import AppPage from "layouts/AppPage";
import { useQuery } from "@tanstack/react-query";
import apiClient from "services/ApiClient";
import { API_ROUTES, SHARED_COMPANY_ROUTES } from "definitions/constants/routeConstants";
import { ExtendedNewHireJourney } from "models";
import { QUERY_KEYS } from "definitions/constants";
import { useEffect } from "react";
import { useNavbar } from "hooks/useNavbar";
import KickOff from "features/PendingNewHire/KickOff";
import { FormStatusProvider } from "contexts/FormStatusContext";
import { Center, Divider, Flex, Text } from "@chakra-ui/react";
import { BlueBorderCard } from "components/Card/BlueBorderCard";
import { dateToFormalLongString } from "helpers/time";
import { useNewHireJourneyDetailQuery } from "features/NewHireJourney/hooks";

function PendingNewHireStepperTitle({ newHireJourney }: { newHireJourney: ExtendedNewHireJourney }) {
  if (!newHireJourney) return null;
  return (
    <Flex justify="center" align="center" width="100%" gap="2" px="8" py="2">
      <Text as="span" fontWeight="bold">
        {newHireJourney?.user?.fullName}
      </Text>
      <Center height="30px">
        <Divider orientation="vertical" borderWidth="1.25px" />
      </Center>
      <Text as="span" fontWeight="bold">
        {newHireJourney?.user?.title}
      </Text>
      <Center height="30px">
        <Divider orientation="vertical" borderWidth="1.25px" />
      </Center>
      <Text>
        Starting
        {" "}
        <Text as="span" fontWeight="bold">
          {dateToFormalLongString(newHireJourney?.startDate)}
        </Text>
      </Text>
    </Flex>
  );
}

export default function PendingNewHireJourneyPage() {
  const { id } = useParams<{ id: string }>();
  const { setBreadcrumbs, setPageTitle } = useNavbar();
  const navigate = useNavigate();

  const { data: newHireJourney, isLoading } = useNewHireJourneyDetailQuery(id as string);

  useEffect(() => {
    if (newHireJourney && !isLoading) {
      if (newHireJourney.isPending === false) {
        navigate(generatePath(SHARED_COMPANY_ROUTES.newHireJourneys.show, { id }));
      }
      setPageTitle(`New Hire ${newHireJourney?.user?.fullName}`);
      setBreadcrumbs([
        {
          name: "Pending New Hires",
          toUrl: generatePath(SHARED_COMPANY_ROUTES.newHireJourneys.pending.index),
          isCurrentPage: false,
        },
        {
          name: newHireJourney?.user?.firstName,
          isCurrentPage: true,
        },
      ]);
    }
  }, [newHireJourney, newHireJourney?.isPending, isLoading, setBreadcrumbs, setPageTitle, navigate, id]);

  return (
    <AppPage>
      <FormStatusProvider>
        <BlueBorderCard
          display="flex"
          justify="center"
          mt="-5"
          position="sticky"
          top="0"
          zIndex="2"
          sx={{ boxShadow: "none", width: "100%" }}
        >
          <PendingNewHireStepperTitle newHireJourney={newHireJourney!} />
        </BlueBorderCard>
        <KickOff newHireJourneyId={id as string} />
      </FormStatusProvider>
    </AppPage>
  );
}
