/* eslint-disable react/jsx-no-useless-fragment */
import { Badge, BadgeProps } from "@chakra-ui/react";
import { Task } from "models";
import { findTaskNotifier } from "features/Task/helpers";
import { statusDateTime } from "helpers/status";
import { TaskCommunication, TaskTableCommunication } from "./AssignedTaskCard/TaskCommunication";

interface TaskStatusProps extends BadgeProps {
  task: Task;
}

export default function TaskStatus({ task, ...rest }: TaskStatusProps) {
  let statusText;
  if (task?.status === "completed") {
    statusText = "Completed";
  } else if (task?.status === "assigned") {
    statusText = "Notification Pending";
  } else if (task?.status === "notified") {
    statusText = "Notification Sent";
  } else if (task?.status === "skipped") {
    statusText = "Skipped";
  } else {
    statusText = "";
  }
  return (
    <Badge
      variant={task?.status === "completed" ? "solid" : "subtle"}
      colorScheme={["assigned", "skipped"].includes(task?.status) ? "gray" : "green"}
      size="lg"
      mt="2"
      borderRadius="5px"
      {...rest}
    >
      {statusText}
    </Badge>
  );
}

function taskStatusTextAndColorScheme(task: Task) {
  if (task?.status === "completed") {
    return {
      statusText: `Completed${task.completedAt ? ` ${statusDateTime(task.completedAt)}` : ""}`,
      colorScheme: "green",
      type: "completed",
    };
  } else if (task?.status === "skipped") {
    return {
      statusText: `Skipped${task.skippedAt ? ` ${statusDateTime(task.skippedAt)}` : ""}`,
      colorScheme: "gray",
      type: "skipped",
    };
  } else if (task?.status === "assigned") {
    const notifier = findTaskNotifier(task);
    if (notifier?.status === "error") {
      return {
        statusText: `Notification Errored${notifier.attemptedAt ? ` ${statusDateTime(notifier.attemptedAt)}` : ""}`,
        colorScheme: "red",
        type: "errored",
      };
      // eslint-disable-next-line no-else-return
    } else if (notifier) {
      return {
        statusText: statusDateTime(notifier?.trigger?.data?.datetime)
          ? `Notification Scheduled ${statusDateTime(notifier?.trigger?.data?.datetime)}`
          : "Notification Scheduling TBD",
        colorScheme: "gray",
        type: "will-notify",
      };
    }
    return {
      statusText: null,
      colorScheme: null,
      type: null,
    };
  } else if (task?.status === "notified") {
    return {
      statusText: `Notified${task.notifiedAt ? ` ${statusDateTime(task.notifiedAt)}` : ""}`,
      colorScheme: "green",
      type: "notified",
    };
  }
  return { statusText: "", colorScheme: "", type: "" };
}

interface MinimalTaskStatusProps extends TaskStatusProps {
  // eslint-disable-next-line react/require-default-props
  excludedStatuses?: string[];
}

export function MinimalTaskStatus({
  task,
  excludedStatuses = ["notified", "will-notify", "completed"],
  ...rest
}: MinimalTaskStatusProps) {
  const { statusText, colorScheme, type } = taskStatusTextAndColorScheme(task);
  if (excludedStatuses.includes(type)) {
    return null;
  }
  return (
    <Badge
      variant={task?.status === "completed" ? "solid" : "subtle"}
      colorScheme={colorScheme}
      size="lg"
      borderRadius="5px"
      {...rest}
    >
      {statusText}
    </Badge>
  );
}


function taskAndNotifierStatusProps(task: Task) {
  if (task?.status === "completed") {
    return {
      statusText: `Completed${task.completedAt ? ` ${statusDateTime(task.completedAt)}` : ""}`,
      colorScheme: "green",
      type: "completed",
    };
  } else if (task?.status === "skipped") {
    return {
      statusText: `Skipped${task.skippedAt ? ` ${statusDateTime(task.skippedAt)}` : ""}`,
      colorScheme: "gray",
      type: "skipped",
    };
  } else if (["assigned", "notified"].includes(task?.status)) {
    const notifier = findTaskNotifier(task);
    return {
      type: "notifier",
      notifier,
    }
  }
  return { statusText: "", colorScheme: "", type: "" };
}

export function TaskStatusWithTime({ task, ...rest }: TaskStatusProps) {
  if (!task) {
    return null;
  }
  const { statusText, colorScheme, type, notifier } = taskAndNotifierStatusProps(task);
  // return nothing for certain statuses that do not have notifier information
  if (!type) {
    return null;
  }

  if (type === "notifier") {
    return (
      <TaskTableCommunication assignedAction={notifier} />
    )
  }

  return (
    <Badge
      variant={task?.status === "completed" ? "solid" : "subtle"}
      colorScheme={colorScheme}
      size="lg"
      borderRadius="5px"
      {...rest}
    >
      {statusText}
    </Badge>
  );
}
